import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Collapse,
  Box,
  Button as ButtonMat,
  Avatar,
} from "@material-ui/core";
import Label from "./Label";
import SearchNotFound from "../SearchNotFound";
import ResultNotFound from "../ResultNotFound";
// import { ListHead, MoreMenu, CustomizedDialogs, ListViewCourse } from ".";
import { ListHead, MoreMenu, InnerList, FormView } from ".";
import { Icon, Button } from "@material-tailwind/react";
import { fDate } from "../../utils/formatTime";
import { onEditTable } from "../../appRedux/actions/clientContacts";
import { deleteClientContact } from "../../appRedux/actions/clientContacts";
//

// ----------------------------------------------------------------------

function Row(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { row, columnHandling, onClickEdit } = props;
  const { _id, subject, contactsInfo, totalSize, email, name } = row;
  const [open, setOpen] = React.useState(false);
  const { editId, editStatus, isEdit, queryInfo } = useSelector((state) =>
    // //console.log({state})
    ({
      editId: state.clientContacts.editView.id,
      editStatus: state.clientContacts.editView.status,
      isEdit: state.clientContacts.editView.isEdit,
      queryInfo: state.clientContacts.queryInfo,
    })
  );

  useEffect(() => {
    if (editStatus && editId != "" && editId === _id && isEdit) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [editId, editStatus, isEdit]);
  //console.log({ editId, editStatus, open, _id, isEdit });
  return (
    <React.Fragment key={_id + "_0"}>
      {open ? (
        <FormView info={row} onClickEdit={onClickEdit} key={_id + "_11"} />
      ) : (
        <TableRow hover key={_id + "_12"}>
          {columnHandling.map((col, index) => {
            const { id, key, childKey, align } = col;
            //console.log({ contactsInfo });

            if (key === "email") {
              return (
                <TableCell
                  key={key}
                  component="th"
                  scope="row"
                  spacing={2}
                  sx={{ padding: "6px 16px" }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar alt={name || email} src={name || email} />
                    {/* <AvatarGroup */}
                    <Typography variant="subtitle2" noWrap>
                      {/* <Link to={`/profile/${_id}/info`}>
                        {email}</Link> */}
                      {email}
                    </Typography>
                  </Stack>
                </TableCell>
              );
            }

            if (key === "active") {
              return (
                <TableCell
                  align="center"
                  sx={{ padding: "6px 16px" }}
                  key={key}
                >
                  <Label
                    variant="ghost"
                    color={(row[key] === false && "error") || "success"}
                  >
                    {row[key] === false ? "Banned" : "Active"}
                  </Label>
                </TableCell>
              );
            }
            if (key === "createdAt") {
              // return <TableCell align={align}>{row[key]}</TableCell>;
              return (
                <TableCell align={align} sx={{ padding: "6px 16px" }} key={key}>
                  {fDate(row[key])}
                </TableCell>
              );
            }
            return key === "action" ? (
              <TableCell
                key={key}
                align={align}
                sx={{ padding: "6px 16px" }}
                id="buttonTable2"
              >
                {/* <MoreMenu learnerId={_id} index={index} /> */}
                <Button
                  color="cyan"
                  className="py-1 my-1 capitalize hover:bg-cyan-500 hover:text-white mx-2"
                  buttonType="filled"
                  onClick={() => {
                    onClickEdit({ id: _id, status: true, isEdit: true });
                    // setOpen(true);
                    // navigate("/history/" + _id);
                  }}
                >
                  Edit
                </Button>
                <Button
                  color="red"
                  className="py-1 my-1 capitalize hover:bg-red-700 hover:text-white mx-2"
                  buttonType="filled"
                  onClick={() => {
                    dispatch(
                      deleteClientContact({ clientContactId: _id, queryInfo })
                    );
                  }}
                >
                  Delete
                </Button>
              </TableCell>
            ) : (
              <TableCell align={align} sx={{ padding: "6px 16px" }} key={key}>
                {row[key] || "N/A"}
              </TableCell>
            );
          })}
        </TableRow>
      )}
    </React.Fragment>
  );
}
export default function ListView() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [courseInfo, setCourseInfo] = useState([]);
  // const [columnHandling, setColumnHandling] = useState([]);

  const {
    searchName,
    clientContactsList,
    columnHandling,
    searchStatus,
    limit,
    editId,
    editStatus,
  } = useSelector((state) =>
    // //console.log({state})
    ({
      clientContactsList: state.clientContacts.clientContactList,
      searchStatus: state.clientContacts.searchStatus,
      // searchName: state.clientContacts.searchName,
      page: state.clientContacts.queryInfo.page,
      limit: state.clientContacts.queryInfo.limit,
      searchName: state.clientContacts.queryInfo.searchText,
      editId: state.clientContacts.editView.id,
      editStatus: state.clientContacts.editView.status,
      columnHandling: state.clientContacts.columnHandling.filter(
        (item) => item.visibility || !item.isFilter
      ),
    })
  );

  useEffect(() => {
    // setColumnHandling(columnHandling2);
  }, []);
  const emptyRows = 0;
  // const emptyRows = Math.max(0, limit - clientContactsList.length) || 0;
  // //console.log({ emptyRows, emptyRows2: limit, length: learnerList.length });

  return (
    <>
      <TableContainer sx={{ minWidth: 280 }}>
        <Table
          sx={{
            padding: "5px 20px",
            "@media (max-width: 450px)": {
              padding: "1px 2px",
            },
          }}
        >
          <ListHead
            headLabel={columnHandling}
            checkbox={false}
            isSortLabel={false}
          />
          <TableBody key={"TableBody-1"}>
            {clientContactsList &&
              clientContactsList.length > 0 &&
              clientContactsList.map((row) => (
                <Row
                  key={row._id + "_rownew"}
                  row={row}
                  columnHandling={columnHandling}
                  editId={editId}
                  editStatus={editStatus}
                  onClickEdit={(value) => {
                    //console.log({ value });
                    dispatch(onEditTable(value));
                  }}
                />
              ))}
          </TableBody>
          <TableBody key={"TableBody-2"}>
            {emptyRows > 0 && clientContactsList.length !== 0 && (
              <TableRow style={{ height: 40 * emptyRows }} key={"1_rownew"}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          {clientContactsList.length === 0 && (
            <TableBody key={"TableBody-3"}>
              <TableRow key={"2_rownew"}>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  {searchName && searchName !== "" ? (
                    <SearchNotFound searchQuery={searchName} />
                  ) : (
                    <ResultNotFound resultsQuery={` Contacts `} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {/* <CustomizedDialogs>
        <ListViewCourse alt="example" {...props} />
      </CustomizedDialogs> */}
    </>
  );
}
