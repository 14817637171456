import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-tailwind/react/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AttachFolder } from ".";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: "2px",
  },
}));

export default function MenuListComposition({ fileListInfo, _DATA }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleCloseSet = (event) => {
    // //console.log(event.target);

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    //console.log({ open, prevOpen });
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  //console.log({ open, prevOpen }, "----------");
  return (
    <div className={classes.root}>
      <div>
        <Button
          color="transparent"
          size="sm"
          buttonType="link"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className="px-0"
        >
          <div className=" font-medium flex items-start text-left p-0 text-black capitalize">
            <AddCircleOutlineIcon fontSize="large" sx={{ color: "#03a9f4" }} />
            <div className={`pl-1  py-0.5  add-more-files `}>
              <p>{"Add more files"}</p>
            </div>
          </div>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
                color: "#ff8200",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <AttachFolder
                      handleClose={() => handleCloseSet()}
                      openFolder={false}
                    />
                    <AttachFolder
                      handleClose={() => handleCloseSet()}
                      openFolder={true}
                    />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
