import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom";

// components
import Navbar from "../Navbar";
import Footer from "./Footer";
// import { hideLoader, hideMessage } from "../../../../appRedux/actions/common";
// import Container from "components/compose/Container";
// import UploadComponent from "../../components/common/UploadComponent";
import DropZoneArea from "../../components/TransferItNow/DropZoneArea";

import { statusDropZone } from "../../appRedux/actions/dropZone";
import CircularStatic from "./Circular";
import Downloading from "./Downloading";
import AlertSnackbar from "../../components/AlertSnackbar";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  //console.log("----------------LoginLayout-------------------");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { url, accessToken, user, setBucketData, download } = useSelector(
    (state) => ({
      url: state.common.initURL,
      download: state.common.download,
      accessToken: state.auth.accessToken,
      user: state.user.user,
      setBucketData: state.transfer.setBucketData,
    })
  );
  useEffect(() => {
    if (!accessToken || !user) {
      navigate("/home", { replace: true });
    } else if (url) {
      const timeout = setTimeout(() => {
        navigate(url, { replace: true });
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [accessToken]);

  const [display, setDisplay] = useState(false);
  const [bucket, setBucket] = useState(false);

  useEffect(() => {
    dispatch(statusDropZone(display));
  }, [display]);
  useEffect(() => {
    // dispatch(statusDropZone(display));
    if (!setBucketData) {
      const timeout = setTimeout(() => {
        setBucket(setBucketData);
      }, 200);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      setBucket(setBucketData);
    }
  }, [setBucketData]);

  return (
    <>
      {/* <AlertSnackbar /> */}
      <div className={`absolute w-full ${bucket ? "" : "z-20"} `}>
        <Navbar />
      </div>
      <RouterLink to="/">{/* <Logo /> */}</RouterLink>
      <div
        id="drop-area"
        onDrop={(e) => {
          e.preventDefault();
          e.persist();
          setDisplay(false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setDisplay(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setDisplay(false);
        }}
      >
        <div className="min-h-[70vh] bg-gray-100">
          <Outlet />
        </div>

        {/* {showCompose && !modelCompose && <Container />} */}
        <Footer />
        <DropZoneArea display={display} />
        {/* <div className="absolute t-0"> */}
        {/* <UploadComponent /> */}
        {/* {dropZoneStatus ? <DropZoneArea /> : ""} */}
      </div>
      {bucket && <CircularStatic open={bucket} />}
      {download && <Downloading download={download} />}
      {/* <DropZoneArea display={display} /> */}
      {/* </div> */}
    </>
  );
}
