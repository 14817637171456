import Card from "@material-tailwind/react/Card";
import CardImage from "@material-tailwind/react/CardImage";
import CardBody from "@material-tailwind/react/CardBody";
import H4 from "@material-tailwind/react/Heading4";
import H6 from "@material-tailwind/react/Heading6";
import LeadText from "@material-tailwind/react/LeadText";
import Paragraph from "@material-tailwind/react/Paragraph";
import Teamwork from "../../assets/img/teamwork.png";

export default function WorkingSection() {
  return (
    <div className="flex flex-wrap items-center mt-20 mb-20">
      <div className="w-full md:w-5/12 px-4 mx-auto">
        <H4 color="gray">Working with us is a pleasure</H4>
        <LeadText color="blueGray">
          TransferItNow arose from our personal need of having a secure and
          effective way of transferring files online, without any exorbitant
          costs attached.
        </LeadText>
        <LeadText color="blueGray">
          Our parent company, Gaviral Gamtec, works in gaming and video
          animations, requiring us to extensively transfer files online.
          However, a dearth of cost-effective options in the market propelled us
          to develop our own solution.
        </LeadText>

        <LeadText color="blueGray">
          The files are transferred in a secure manner, through especially
          generated links that become inactive after 24 hours.
        </LeadText>
      </div>

      <div className="w-full md:w-4/12 px-4 mx-auto flex justify-center mt-24 lg:mt-0">
        <Card>
          <CardImage alt="Card Image" src={Teamwork} />
          <CardBody>
            <H6 color="gray">TransferItNow</H6>
            <Paragraph color="blueGray">
              It is a file-sharing web application that allows users to transfer
              files of all formats online, free of cost (with a limit of upto
              4GB per transfer).
            </Paragraph>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
