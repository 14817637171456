import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
// import { Icon } from "@iconify/react";
// import searchFill from "@iconify/icons-eva/search-fill";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
  Box,
  // Button,
  Toolbar,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import {
  getTransfers,
  onChangeSearchName,
} from "../../appRedux/actions/transfer";
import { Icon, Button } from "@material-tailwind/react";
import { onClearSearchName } from "../../appRedux/actions/transfer";
import { FormAdd } from ".";
import {
  getClientContact,
  onEditTable,
} from "../../appRedux/actions/clientContacts";

// ----------------------------------------------------------------------
// import { FilterSidebar } from ".";
// import {
//   getLearnerList,
//   onChangeSearchName,
// } from "../../../../appRedux/actions/learner";

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 450px)": {
    display: "grid",
    height: 140,
    justifyContent: "center",
  },
  // padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 360 },
  "& fieldset": {
    borderWidth: `1px !important`,
  },
  "@media (max-width: 400px)": {
    width: 260,
    "&.Mui-focused": { width: 260 },
    "& fieldset": {
      borderWidth: `1px !important`,
    },
  },
  // "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  // "& fieldset": {
  //   borderWidth: `1px !important`,
  //   borderColor: `${theme.palette.grey["500_32"]} !important`,
  // },
}));

// ----------------------------------------------------------------------

export default function ListToolbar() {
  const dispatch = useDispatch();

  // page: 0,
  // limit: 10,
  // received: false,
  const { searchName, queryInfo, searchStatus, editId, editStatus, isEdit } =
    useSelector((state) => ({
      searchName: state.transfer.searchName,
      searchStatus: state.transfer.searchStatus,
      queryInfo: state.transfer.queryInfo,
      received: state.transfer.queryInfo.received,
      editId: state.clientContacts.editView.id,
      editStatus: state.clientContacts.editView.status,
      isEdit: state.clientContacts.editView.isEdit,
    }));
  useEffect(() => {
    if (searchStatus) {
      //console.log({ queryInfo });
      const timeout = setTimeout(() => {
        dispatch(
          getClientContact({
            ...queryInfo,
            searchText: searchName !== "" ? searchName : undefined,
          })
        );
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchName, searchStatus]);

  useEffect(() => {
    if (editStatus && editId == "" && !isEdit) {
      setOpenFilter(true);
    } else {
      setOpenFilter(false);
    }
  }, [editId, editStatus, isEdit]);

  const [openFilter, setOpenFilter] = useState(false);
  return (
    <>
      {!openFilter ? (
        <RootStyle id="SearchStyle">
          <SearchStyle
            value={searchName}
            onChange={(event) => {
              dispatch(onChangeSearchName(event.target.value));
            }}
            placeholder="Search by email, name and company name"
            name=""
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }} />
              </InputAdornment>
            }
          />
          <div className="flex  w-max gap-4">
            <Button
              className="mx-2 border border-light-blue-500 capitalize"
              buttonType={"filled"}
              onClick={() => {
                dispatch(onEditTable({ id: "", status: true, isEdit: false }));
              }}
            >
              <Icon name={"person_add"} />
              &nbsp; Add
            </Button>
          </div>
        </RootStyle>
      ) : (
        <RootStyle id="SearchStyle">
          <FormAdd
            queryInfo={queryInfo}
            setOpenFilter={() => {
              dispatch(onEditTable({ id: "", status: false, isEdit: false }));
            }}
          />
        </RootStyle>
      )}
    </>
  );
}
