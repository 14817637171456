import React, { useState, useEffect, useCallback } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { setFileData } from "../../appRedux/actions/dropZone";
import { statusDropZone } from "../../appRedux/actions/dropZone";

import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../common/WindowDimensions";

import JSZip from "jszip";
import throttle from "lodash.throttle";

import async from "async";

export default function DropZoneAreaInner({ display = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [active, setActive] = useState(false);
  const { fileListInfo, dropZoneStatus } = useSelector((state) => ({
    fileListInfo: state.dropZone.fileListInfo,
    dropZoneStatus: state.dropZone.dropZoneStatus,
  }));

  const handleDrop = (acceptedFiles) => {
    var result = acceptedFiles.reduce(function (r, a) {
      let url = a.path.split("/");
      let fileName = url.length > 1 ? url[1] : a.path;
      r[fileName] = r[fileName] || [];
      r[fileName].push(a);
      return r;
    }, Object.create(null));

    async.mapSeries(
      Object.keys(result),
      function (info, next) {
        let isFolder = info.split(".").length > 1 ? false : true;
        let fileInfo = isFolder ? {} : result[info][0];
        let size = isFolder
          ? result[info].reduce((accumulator, object) => {
              return accumulator + object.size;
            }, 0)
          : fileInfo.size;
        if (isFolder) {
          onZip(result[info], function (err, data) {
            if (err) {
              next(err);
            } else {
              next(null, {
                name: info,
                type: "Folder",
                size: size,
                // bucket: Math.random(),
                alteredName:
                  new Date().getTime() +
                  "_" +
                  Math.random().toString().split(".").pop() +
                  ".zip",
                files: data,
                numberOfFile: result[info].length,
              });
            }
          });
        } else {
          next(null, {
            name: info,
            type: fileInfo.type,
            size: size,
            // bucket: Math.random(),
            alteredName:
              new Date().getTime() +
              "_" +
              Math.random().toString().split(".").pop() +
              "." +
              info.split(".").pop(),
            files: result[info][0],
            numberOfFile: result[info].length,
          });
        }
      },
      function (err, results) {
        //console.log({ err, results });
        // callback(err, listSuccess);

        dispatch(setFileData(results));
        // navigate("/transferit");
        // handleClose();
      }
    );
    // dispatch(setFileData(result));
  };
  const [progress, setProgress] = useState(-1);

  const onZipUpdate = (metadata) => {
    setProgress(metadata.percent);
    //console.log("progression: " + metadata.percent.toFixed(2) + " %");
    if (metadata.currentFile) {
      //console.log("current file = " + metadata.currentFile);
    }
  };
  const throttledZipUpdate = throttle(onZipUpdate, 50);

  const onZip = function onZip(fileList, callback) {
    const zip = new JSZip();
    const files = Array.from(fileList);
    // setFiles(inputRef.current.files);
    files.forEach((file) => {
      zip.file(file.webkitRelativePath, file);
    });
    zip
      .generateAsync({ type: "blob" }, throttledZipUpdate)
      .then(function (content) {
        callback(null, content);
        // saveAs(content, "files.zip");

        // const formData = new FormData();
        // formData.append("folderzip", content);
        // //console.log("ready to send to server", content);
      })
      .catch((e) => callback(e));
  };
  useEffect(() => {
    //console.log({ display }, "---uuu----------");
    if (typeof display == "boolean") {
      if (!display) {
        const timeout = setTimeout(() => {
          setActive(display);
        }, 500);
        return () => {
          clearTimeout(timeout);
        };
      } else {
        setActive(display);
      }
    }
  }, [display]);

  return (
    <div
      className={`App ${
        active ? " active" : ""
      } px-5 pt-10 items-center justify-center text-center dropzone-area-inner `}
    >
      <Dropzone
        onDrop={handleDrop}
        // minSize={1024}
        // maxSize={3072000}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
        }) => {
          const additionalClass = isDragAccept
            ? "accept"
            : isDragReject
            ? "reject"
            : "";

          return (
            <div
              {...getRootProps({
                className: `dropzone  ${additionalClass} 
                p-[20px] mt-10`,
              })}
            >
              <input {...getInputProps()} />
              <span>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  // style={{ fontSize: "150px", width: "100%" }}
                >
                  <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
                </svg>
              </span>
              <p className="py-10 text-sx">
                Drag & drop files, or click to select files
              </p>
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
}
