import {
  TRANSFER_CREATE_SUCCESS,
  TRANSFER_LIST,
  TRANSFER_LIST_SUCCESS,
  TRANSFER_INFO_SUCCESS,
  TRANSFER_UPDATE_SUCCESS,
  TRANSFER_DELETE_SUCCESS,
  TRANSFER_FILTER_HANDLING,
  TRANSFER_SEARCH_NAME,
  TRANSFER_SELECTED,
  TRANSFER_SELECTED_TUCHED,
  TRANSFER_SELECTED_RESET,
  TRANSFER_SEARCH_NAME_CLEAR,
  TRANSFER_RECEIVED,
  TRANSFER_BUCKET_STATUS,
  TRANSFER_PROGRESS,
  TRANSFER_DETAILS_INFO_SUCCESS,
  TRANSFER_CENCEL,
} from "../actionTypes/transfer";
import {
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
} from "../actionTypes/common";
import { userSignOut } from "./auth";
// import AllDataService from '../services/all.service';

import axiosHttp from "../services/axiosCommon";
// getTransfersList;

export const setTransferCencel = () => async (dispatch) => {
  dispatch({
    type: TRANSFER_CENCEL,
  });
};
export const setTransferBucketStatus = (dataInfo) => async (dispatch) => {
  //console.log({ dataInfo });
  dispatch({
    type: TRANSFER_BUCKET_STATUS,
    payload: dataInfo,
  });
};
export const selectedDataTransfers = (dataInfo) => async (dispatch) => {
  //console.log({ dataInfo });
  dispatch({
    type: TRANSFER_SELECTED,
    payload: dataInfo,
  });
};
export const resetSelectedDataTransfers = () => async (dispatch) => {
  dispatch({
    type: TRANSFER_SELECTED_RESET,
  });
};

export const getAllTransferList = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/transfer/list", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: TRANSFER_LIST,
        payload: data,
      });

      dispatch({
        type: ON_HIDE_LOADER,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getTransfers = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  const { page, searchText } = queryInfo;
  try {
    const result = await axiosHttp.get("/transfer", {
      params: queryInfo,
    });
    const { data } = result;
    if (data.error && data.status !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/transfer/list",
      // });
      // dispatch({
      //   type: ON_SHOW_MESSAGE,
      //   payload: data,
      // });
      if ((!searchText || searchText === "") && page === 0) {
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: data,
        });
      } else {
        dispatch({ type: ON_HIDE_LOADER });
      }
      dispatch({
        type: TRANSFER_LIST_SUCCESS,
        payload: { ...data, queryInfo },
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getTransfersInfo = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/transfer/info", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: TRANSFER_INFO_SUCCESS,
        payload: data,
      });

      dispatch({
        type: ON_HIDE_LOADER,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getDetailsInfo = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/details/info", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      dispatch({
        type: TRANSFER_DETAILS_INFO_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
// details

export const downloadTransfer = (infoData) => async (dispatch) => {
  //console.log({ infoData }, "addTransfers-------------+++++++++++===");

  try {
    const result = await axiosHttp.post("/transfer/download", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      // dispatch({
      //   type: ON_SHOW_MESSAGE,
      //   payload: data,
      // });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const sendTransferEmail = (infoData) => async (dispatch) => {
  //console.log({ infoData }, "addTransfers-------------+++++++++++===");

  dispatch({
    type: INIT_URL,
    payload: "/transferit",
  });
  // dispatch({
  //   type: ON_SHOW_LOADER,
  // });
  try {
    const result = await axiosHttp.post("/transfer/done", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/history",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const sendTransfer = (infoData) => async (dispatch) => {
  //console.log({ infoData }, "addTransfers-------------+++++++++++===");
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.post("/transfer", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/transferit",
      });
      // dispatch({
      //   type: ON_SHOW_MESSAGE,
      //   payload: data,
      // });

      dispatch({
        type: ON_HIDE_LOADER,
      });
      dispatch({
        type: TRANSFER_CREATE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const updateTransfers = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  //console.log({ infoData }, "------------");
  try {
    const result = await axiosHttp.put("/transfer", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/transfer/list",
      // });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: TRANSFER_UPDATE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const deleteTransfers = (infoData) => async (dispatch) => {
  const { queryInfo } = infoData;
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.delete("/transfer", {
      params: { ...infoData, queryInfo: undefined },
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      if (queryInfo) {
        dispatch(getTransfers(queryInfo));
      }
      dispatch({
        type: TRANSFER_DELETE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const transferRecived = (info) => async (dispatch) => {
  dispatch({
    type: TRANSFER_RECEIVED,
    payload: info,
  });
};
export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: TRANSFER_SEARCH_NAME,
    payload: info,
  });
};

export const onClearSearchName = () => async (dispatch) => {
  dispatch({
    type: TRANSFER_SEARCH_NAME_CLEAR,
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: TRANSFER_FILTER_HANDLING,
    payload: info,
  });
};
