import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
// import TagFacesIcon from "@material-ui/icons/TagFaces";
import { TextField } from "@material-ui/core";
import { Label } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";

import { selectedDataClientContacts } from "../../appRedux/actions/clientContacts";
import { EmailChips, SelectAndAddNew } from ".";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "center",
    borderRadius: "0.75rem",
    // minHeight: "100px",
    marginBottom: "10px",
    flexWrap: "wrap",
    // padding: theme.spacing(0, 0, 0),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  chip: {
    // maxWidth: CHIP_MAX_WIDTH,
    margin: "4px 2px 4px 2px",
    fontSize: "12px",
  },
}));

export default function SelectView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedData } = useSelector((state) => ({
    selectedData: state.clientContacts.selectedData,
  }));

  const { values, touched, errors } = selectedData;
  return (
    <>
      <Paper className={`${classes.root} `}>
        <Label color={"black"}>To Email</Label>
        <div className="mb-1 mt-0 mx-4 bg-bb w-full " id="fieldset">
          <SelectAndAddNew />
        </div>
        {/* {values.contactIds && values.contactIds.length > 0 && <EmailChips />} */}

        <p className="text-red-500 px-4">
          {touched.contactIds && errors.contactIds && errors.contactIds !== ""
            ? errors.contactIds
            : ""}
        </p>
      </Paper>
    </>
  );
}
