import { SIGNOUT_AUTH_USER_SUCCESS } from "../actionTypes/auth";
import { TRANSFER_SELECTED_RESET } from "../actionTypes/transfer";
import {
  DROP_ZONE_LIST,
  DROP_ZONE_CREATE_SUCCESS,
  DROP_ZONE_REMOVE_OBJECT,
  DROP_ZONE_INFO_SUCCESS,
  DROP_ZONE_FILTER_HANDLING,
  DROP_ZONE_SEARCH_NAME,
  DROP_ZONE_UPDATE_SUCCESS,
  DROP_ZONE_STATUS,
  DROP_ZONE_PUSH,
} from "../actionTypes/dropZone";

const INIT_STATE = {
  fileListInfo: [],
  listData: [],
  dropZoneStatus: false,
  numOfFolder: 0,
  numOfFile: 0,
  totalSize: 0,
};

function dropZoneReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  // TRANSFER_SELECTED_RESET
  switch (type) {
    case SIGNOUT_AUTH_USER_SUCCESS: {
      return {
        fileListInfo: [],
        listData: [],
        dropZoneStatus: false,
        numOfFolder: 0,
        numOfFile: 0,
        totalSize: 0,
      };
    }
    case TRANSFER_SELECTED_RESET: {
      return {
        ...state,
        fileListInfo: [],
      };
    }
    case DROP_ZONE_PUSH: {
      // var listInfo = { ...state.fileListInfo, ...payload };
      var listInfo = state.fileListInfo.concat(payload);
      let numOfFolder = 0;
      let numOfFile = 0;
      let totalSize = 0;

      listInfo.map((info) => {
        let isFolder = info.name.split(".").length > 1 ? false : true;
        if (isFolder) {
          numOfFolder = numOfFolder + 1;
        } else {
          numOfFile = numOfFile + 1;
        }
        // let fileInfo = info;
        let size = info.size;
        totalSize = totalSize + size;
      });
      return {
        ...state,
        fileListInfo: state.fileListInfo.concat(payload), // { ...state.fileListInfo, ...payload },
        numOfFolder: numOfFolder,
        numOfFile: numOfFile,
        totalSize: totalSize,
      };
    }
    case DROP_ZONE_STATUS: {
      return {
        ...state,
        dropZoneStatus: payload,
      };
    }
    case DROP_ZONE_REMOVE_OBJECT: {
      //console.log({ payload, fileListInfo: state.fileListInfo });
      var fileListInfo = state.fileListInfo.filter(
        (info) => info.alteredName !== payload.alteredName
      );
      // delete fileListInfo[payload];
      let numOfFolder = 0;
      let numOfFile = 0;
      let totalSize = 0;
      var listData = [];
      // Object.keys(fileListInfo).map((info) => {
      //   let isFolder = info.split(".").length > 1 ? false : true;
      //   if (isFolder) {
      //     numOfFolder = numOfFolder + 1;
      //   } else {
      //     numOfFile = numOfFile + 1;
      //   }
      //   let fileInfo = isFolder ? {} : fileListInfo[info][0];
      //   let size = isFolder
      //     ? fileListInfo[info].reduce((accumulator, object) => {
      //         return accumulator + object.size;
      //       }, 0)
      //     : fileInfo.size;
      //   totalSize = totalSize + size;
      //   listData.push({
      //     name: info,
      //     type: isFolder ? "Folder" : fileInfo.type,
      //     size: size,
      //     // bucket: Math.random(),
      //     alteredName: isFolder
      //       ? new Date().getTime() +
      //         "_" +
      //         Math.random().toString().split(".").pop() +
      //         ".zip"
      //       : new Date().getTime() +
      //         "_" +
      //         Math.random().toString().split(".").pop() +
      //         "." +
      //         info.split(".").pop(),
      //     info: listInfo[info],
      //     numberOfFile: listInfo[info].length,
      //   });
      // });

      fileListInfo.map((info) => {
        let isFolder = info.name.split(".").length > 1 ? false : true;
        if (isFolder) {
          numOfFolder = numOfFolder + 1;
        } else {
          numOfFile = numOfFile + 1;
        }
        // let fileInfo = info;
        let size = info.size;
        // isFolder
        //   ? fileListInfo[info].reduce((accumulator, object) => {
        //       return accumulator + object.size;
        //     }, 0)
        //   : fileInfo.size;
        totalSize = totalSize + size;
        // listData.push({
        //   name: info,
        //   type:   fileInfo.type,
        //   size: size,
        //   // bucket: Math.random(),
        //   alteredName: isFolder
        //     ? new Date().getTime() +
        //       "_" +
        //       Math.random().toString().split(".").pop() +
        //       ".zip"
        //     : new Date().getTime() +
        //       "_" +
        //       Math.random().toString().split(".").pop() +
        //       "." +
        //       info.split(".").pop(),
        //   info: listInfo[info],
        //   numberOfFile: listInfo[info].length,
        // });
      });
      //console.log({ totalSize });
      return {
        ...state,
        fileListInfo: fileListInfo,
        numOfFolder: numOfFolder,
        numOfFile: numOfFile,
        totalSize: totalSize,
        // listData: listData,
      };
    }

    // case DROP_ZONE_LIST_SUCCESS: {
    //   //console.log({ payload });
    //   return {
    //     ...state,
    //     searchStatus: false,
    //     dropZoneList: payload.data,
    //     queryInfo: {
    //       ...payload.queryInfo,
    //     },
    //     totalRecords: payload.totalRecords,
    //     isSuccess: false,
    //     // page: payload.queryInfo.page || 0
    //   };
    // }

    // case DROP_ZONE_CREATE_SUCCESS: {
    //   //console.log({ payload });
    //   return {
    //     ...state,
    //     dropZoneList: [payload.data, ...state.dropZoneList],
    //     isSuccess: true,
    //   };
    // }

    // case DROP_ZONE_UPDATE_SUCCESS: {
    //   //console.log({ payload });
    //   return {
    //     ...state,
    //     dropZoneList: state.dropZoneList.map(
    //       (obj) => (payload.data._id === obj._id ? payload.data : obj)
    //       // data.columnHandling.map((row) => obj.id === row)
    //     ),
    //     dropZoneInfo: payload.data,
    //     isSuccess: true,
    //     isUpdate: true,
    //   };
    // }

    // case DROP_ZONE_INFO_SUCCESS: {
    //   //console.log({ payload });
    //   return {
    //     ...state,
    //     dropZoneInfo: payload.data,
    //     // isSuccess: true
    //   };
    // }
    // case DROP_ZONE_FILTER_HANDLING: {
    //   //console.log({ payload });
    //   return {
    //     ...state,
    //     columnHandling: payload.columnHandling,
    //     dropZoneType: payload.dropZoneType,
    //   };
    // }
    // case DROP_ZONE_SEARCH_NAME: {
    //   //console.log({ payload });
    //   return {
    //     ...state,
    //     searchName: payload,
    //     searchStatus: true,
    //     queryInfo: { page: 0, limit: state.queryInfo.limit },
    //   };
    // }

    default:
      return state;
  }
}

export default dropZoneReducer;
