//   column_Handling;
// _id: '60ef79f8d685ff572cdb8242';
// description: 'Ansal, Golf Link -2';
// landlordId: null;
// monthlyRent: 12000;
// name: 'AD-172, Ansal, Golf Link -2';
// otherPayment: 2000;
// otherPaymentType: 'PER';
// paymentType: 'INR';
// postalCode: 203306;
// totalMonthlyRent: 14000;
export const COLUMN_HANDLING = [
  {
    id: 'Number',
    key: 'number',
    label: 'Residence number',
    align: 'left',
    pading: '0 3',
    visibility: true,
    isFilter: true
  },

  {
    id: 'Landlord',
    key: 'landlordInfo',
    childKey: 'name',
    label: 'Landlord',
    align: 'left',
    visibility: true,
    isFilter: true
  },
  {
    id: 'Category',
    key: 'categoryInfo',
    childKey: 'name',
    label: 'Category',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'Description',
    key: 'description',
    label: 'Description',
    align: 'left',
    visibility: true,
    isFilter: true
  },
  {
    id: 'Status',
    key: 'occupied',
    label: 'Status',
    align: 'left',
    visibility: true,
    isFilter: true
  },
  { id: 'City', key: 'city', label: 'City', align: 'left', visibility: true, isFilter: true },
  {
    id: 'Address',
    key: 'address',
    label: 'Address',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'PostalCode',
    key: 'postalCode',
    label: 'Postal Code',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'TotalRent',
    key: 'totalMonthlyRent',
    label: 'Total Rent',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'CreatedAt',
    key: 'createdAt',
    label: 'Created At',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'Action',
    key: 'action',
    label: 'Action',
    align: 'left',
    visibility: true,
    isFilter: false
  }
];
