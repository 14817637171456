import { CLIENT_UPDATE_SUCCESS } from "../actionTypes/client";
import {
  SIGNOUT_AUTH_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  ALL_CLEAR_SUCCESS,
  AUTH_LEVEL,
  USER_CONTACT_US,
  USER_CONTACT_US_SUCCESS,
} from "../actionTypes/auth";
import {
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_HIDE_MESSAGE,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
} from "../actionTypes/common";
// import AllDataService from '../services/all.service';
import axiosHttp from "../services/axiosCommon";

export const authLevelChannge = (info) => async (dispatch) => {
  dispatch({
    type: AUTH_LEVEL,
    payload: info,
  });
};
export const userSignIn = (user) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.post("/client/login", user);
    const { data } = result;

    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      localStorage.setItem("accessToken", data.authToken);
      localStorage.setItem("user", JSON.stringify(data.data));
      dispatch({
        type: INIT_URL,
        payload: "/transferit",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: SIGNIN_USER_SUCCESS,
        payload: data,
      });
      dispatch({ type: CLIENT_UPDATE_SUCCESS, payload: data });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    const { data } = response;
    //console.log({ err });
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        userMessage:
          data && data.userMessage
            ? data.userMessage
            : "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};

export const userSignUp = (user) => async (dispatch) => {
  try {
    const result = await axiosHttp.post("/client/signup", user);
    const { data } = result;
    //console.log({ data }, "----------------client");
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      //console.log({ data });
      localStorage.setItem("tempInfo", data);
      // localStorage.setItem("user", JSON.stringify(data.data));
      dispatch({
        type: INIT_URL,
        payload: "/auth/verify-code/" + data.data.tempToken,
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: SIGNUP_USER_SUCCESS,
        payload: data,
      });

      dispatch({ type: CLIENT_UPDATE_SUCCESS, payload: data });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;

    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        userMessage: "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};

export const userSetNewPassword = (user) => async (dispatch) => {
  try {
    const result = await axiosHttp.post("/client/forgot/password", user);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      localStorage.setItem("accessToken", data.authToken);
      localStorage.setItem("user", JSON.stringify(data.data));
      dispatch({
        type: INIT_URL,
        payload: "/transferit",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: SIGNIN_USER_SUCCESS,
        payload: data,
      });

      dispatch({ type: CLIENT_UPDATE_SUCCESS, payload: data });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;

    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        userMessage: "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};
export const userCodeSend = (info) => async (dispatch) => {
  const { type } = info;
  try {
    const result = await axiosHttp.post("/client/code/send", info);
    const { data } = result;
    //console.log({ data }, "----------------client");
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      //console.log({ data });
      localStorage.setItem("tempInfo", data);
      // localStorage.setItem("user", JSON.stringify(data.data));
      if (type === "SignUp") {
        dispatch({
          type: INIT_URL,
          payload: "/auth/verify-code/" + data.data.tempToken,
        });
      } else {
        dispatch({
          type: INIT_URL,
          payload: "/auth/new-password/" + data.data.tempToken,
        });
      }
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: SIGNUP_USER_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;

    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        userMessage: "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};
// client/code/verify

export const userCodeVerify = (info) => async (dispatch) => {
  try {
    const result = await axiosHttp.post("/client/code/verify", info);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      localStorage.setItem("accessToken", data.authToken);
      localStorage.setItem("user", JSON.stringify(data.data));
      dispatch({
        type: INIT_URL,
        payload: "/transferit",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: SIGNIN_USER_SUCCESS,
        payload: data,
      });

      dispatch({ type: CLIENT_UPDATE_SUCCESS, payload: data });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;

    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        userMessage: "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};
// /client/code/send
export const getUserTempToken = (queryInfo) => async (dispatch) => {
  try {
    const result = await axiosHttp.get("/client/temp/token", {
      params: queryInfo,
    });
    const { data } = result;
    //console.log({ data }, "----------------client");
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      //console.log({ data });
      localStorage.setItem("tempInfo", data);
      // localStorage.setItem("user", JSON.stringify(data.data));
      if (data.data.type !== "7e23cf7f1409") {
        dispatch({
          type: INIT_URL,
          payload: "/auth/verify-code/" + data.data.tempToken,
        });
      } else {
        dispatch({
          type: INIT_URL,
          payload: "/auth/new-password/" + data.data.tempToken,
        });
      }
      dispatch({
        type: ON_HIDE_LOADER,
      });
      dispatch({
        type: SIGNUP_USER_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;

    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        userMessage: "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};
// /client/temp/token
export const userSignOut = (info) => async (dispatch) => {
  //console.log("userSignOut--------");
  dispatch({
    type: INIT_URL,
    payload: "/auth/login",
  });

  dispatch({
    type: SIGNOUT_AUTH_USER_SUCCESS,
  });
  localStorage.clear();
  if (info && info.changeMessage === true) {
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: info.payload,
    });
  } else {
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        userMessage: "Logout successfully",
        status: 200,
        error: true,
      },
    });
  }
  const timeout = setTimeout(() => {
    dispatch({
      type: ON_HIDE_MESSAGE,
    });
    dispatch({
      type: ALL_CLEAR_SUCCESS,
    });
  }, 3000);
  return () => {
    clearTimeout(timeout);
  };

  // try {
  //   const result = await axiosHttp.post('/logout', {});
  //   const { data } = result;
  // dispatch({
  //   type: INIT_URL,
  //   payload: "/auth/login"
  // });
  // dispatch({
  //   type: ON_SHOW_MESSAGE,
  //   payload: { userMessage: 'Logout successfully', status: 200, error: false }
  // });
  // dispatch({
  //   type: SIGNOUT_AUTH_USER_SUCCESS
  // });
  // if (data.error) {
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: data
  //   });
  // } else {
  //   localStorage.setItem('accessToken', data.authToken);
  //   localStorage.setItem('user', JSON.stringify(data.data));
  //   dispatch({
  //     type: INIT_URL,
  //     payload: "/auth/login"
  //   });
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: data
  //   });
  //   dispatch({
  //     type: SIGNIN_USER_SUCCESS,
  //     payload: data
  //   });
  // }

  //   return Promise.resolve(data);
  // } catch (err) {
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: { userMessage: 'Request failed', status: 500, error: true }
  //   });
  //   return Promise.reject(err);
  // }
};
export const tokenRemove = () => async (dispatch) => {
  // dispatch({
  //   type: TOKEN_REMOVE,
  // });
};

export const userContactUs = (user) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  dispatch({
    type: USER_CONTACT_US,
  });

  try {
    const result = await axiosHttp.post("/contactus", user);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: USER_CONTACT_US_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    const { data } = response;
    //console.log({ err });
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        userMessage:
          data && data.userMessage
            ? data.userMessage
            : "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};
// export const userSignInSuccess = (authUser) => async (dispatch) => {
//   try {
//     const res = await TutorialDataService.create({ title, description });

//     dispatch({
//       type: CREATE_TUTORIAL,
//       payload: res.data
//     });

//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };

// export const retrieveTutorials = () => async (dispatch) => {
//   try {
//     const res = await TutorialDataService.getAll();

//     dispatch({
//       type: RETRIEVE_TUTORIALS,
//       payload: res.data
//     });
//   } catch (err) {
//     //console.log(err);
//   }
// };

// export const updateTutorial = (id, data) => async (dispatch) => {
//   try {
//     const res = await TutorialDataService.update(id, data);

//     dispatch({
//       type: UPDATE_TUTORIAL,
//       payload: data
//     });

//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
