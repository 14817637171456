// import { SIGNIN_USER_ERROR, SIGNIN_USER_SUCCESS } from '../actionTypes/auth';
import { SIGNOUT_AUTH_USER_SUCCESS } from "../actionTypes/auth";
import { TRANSFER_SELECTED_RESET } from "../actionTypes/transfer";
import { stat } from "fs";
import { initURL } from "../actions/common";
import {
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_HIDE_MESSAGE,
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_COMPOSE_STATUS,
  SET_PROGRESS,
  SET_FILE_STATUS,
  SET_DOWNLOAD,
} from "../actionTypes/common";

const INIT_STATE = {
  loader: false,
  download: false,
  showMessage: false,
  messageStatus: "",
  message: "",
  userMessage: "",
  initURL: "",
  showCompose: false,
  modelCompose: false,
  progress: 0,
  totalSize: 0,
  loadedFileSize: 0,
  loadedSize: 0,
  numberOfFile: 0,
};

function AuthReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNOUT_AUTH_USER_SUCCESS: {
      return {
        loader: false,
        download: false,
        showMessage: false,
        messageStatus: "",
        message: "",
        userMessage: "",
        initURL: "",
        showCompose: false,
        modelCompose: false,
        progress: 0,
        totalSize: 0,
        loadedFileSize: 0,
        loadedSize: 0,
        numberOfFile: 0,
      };
    }
    case TRANSFER_SELECTED_RESET: {
      return {
        ...state,
        progress: 0,
        totalSize: 0,
        loadedFileSize: 0,
        loadedSize: 0,
        numberOfFile: 0,
      };
    }
    case SET_DOWNLOAD: {
      return {
        ...state,
        download: payload,
      };
    }
    case SET_PROGRESS: {
      //console.log({ state, payload });
      // var loaded =
      //   payload.numberOfFile !== state.numberOfFile
      //     ? state.loadedSize + state.loadedFileSize + payload.loaded
      //     : state.loadedSize + payload.loaded;
      var progress = state.progress;
      if (payload.progress && payload.progress > state.progress) {
        progress = payload.progress;
      } else if (payload.loaded) {
        progress = Math.round((payload.loaded / state.totalSize) * 100);
      }
      return {
        ...state,
        progress: progress,
        // loadedSize: loaded,
        loadedFileSize: payload.loaded ? payload.loaded : state.totalSize,
      };
    }
    case SET_FILE_STATUS: {
      //console.log({ state, payload });
      return {
        ...state,
        loadedSize: state.loadedFileSize + state.loadedSize,
        totalLoaded: payload.totalLoaded
          ? payload.totalLoaded
          : state.totalLoaded,
        totalSize: payload.totalSize ? payload.totalSize : state.totalSize,
        numberOfFile: payload.numberOfFile
          ? payload.numberOfFile
          : state.numberOfFile,
      };
    }

    case ON_COMPOSE_STATUS: {
      return {
        ...state,
        showCompose: payload.showCompose,
        modelCompose: payload.modelCompose,
      };
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: payload,
      };
    }

    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
        // showMessage: false,
      };
    }

    case ON_SHOW_MESSAGE: {
      //console.log({ payload }, "ON_SHOW_MESSAGE---");
      return {
        ...state,
        loader: false,
        showMessage: true,
        messageStatus: payload.error ? "error" : "success",
        message: payload.userMessage,
      };
    }
    case ON_HIDE_MESSAGE: {
      //console.log({ payload });
      return {
        ...state,
        loader: false,
        showMessage: false,
      };
    }

    default:
      return state;
  }
}

export default AuthReducer;
