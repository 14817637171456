import {
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_HIDE_MESSAGE,
  ON_SHOW_MESSAGE,
  //   SUCCESS_MESSAGE,
  ON_COMPOSE_STATUS,
  INIT_URL,
  SET_PROGRESS,
  SET_FILE_STATUS,
  SET_DOWNLOAD,
} from "../actionTypes/common";

export const setDownload = (dataInfo) => async (dispatch) => {
  //console.log({ dataInfo }, "payload");
  dispatch({
    type: SET_DOWNLOAD,
    payload: dataInfo,
  });
};
export const setProgress = (dataInfo) => async (dispatch) => {
  //console.log({ dataInfo }, "payload");
  dispatch({
    type: SET_PROGRESS,
    payload: dataInfo,
  });
};
export const setFileStatus = (dataInfo) => async (dispatch) => {
  //console.log({ dataInfo }, "payload");
  dispatch({
    type: SET_FILE_STATUS,
    payload: dataInfo,
  });
};
export const initURL = (url) => async (dispatch) => {
  dispatch({
    type: INIT_URL,
    payload: url,
  });
};

export const ComposeStatus = (info) => async (dispatch) => {
  dispatch({
    type: ON_COMPOSE_STATUS,
    payload: info,
  });
};

export const showLoader = () => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
};

export const hideLoader = () => async (dispatch) => {
  dispatch({
    type: ON_HIDE_LOADER,
  });
};

export const showMessage = (info) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_MESSAGE,
    payload: info,
  });
};
export const hideMessage = () => async (dispatch) => {
  dispatch({
    type: ON_HIDE_MESSAGE,
  });
};
