import {
  SIGNIN_USER_ERROR,
  SIGNIN_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  SIGNOUT_AUTH_USER_SUCCESS,
  ALL_CLEAR_SUCCESS,
  AUTH_LEVEL,
} from "../actionTypes/auth";

export const AUTH_INIT_STATE = {
  authLevel: 1,
  tempInfo: {},
  user: JSON.parse(localStorage.getItem("user")) || {},
  accessToken: localStorage.getItem("accessToken"),
  routeLevel: JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user")).accessLevel
    : 0 || 0,
};

function AuthReducer(state = AUTH_INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case AUTH_LEVEL: {
      return {
        ...state,
        authLevel: payload,
      };
    }

    case SIGNIN_USER_SUCCESS: {
      state.initURL = "/"; // state.user && state.user.trainee? "/trainee":"/main/dashboard/crm"
      //console.log({ payload });
      return {
        ...state,
        user: payload.data,
        accessToken: payload.authToken,
        routeLevel: payload.data.accessLevel,
      };
    }
    case SIGNUP_USER_SUCCESS: {
      state.initURL = "/"; // state.user && state.user.trainee? "/trainee":"/main/dashboard/crm"
      //console.log({ payload });
      return {
        ...state,
        tempInfo: payload.data,
        // accessToken: payload.authToken,
        // routeLevel: payload.data.accessLevel
      };
    }

    case SIGNIN_USER_ERROR: {
      //console.log({ payload });
      return {
        ...state,
        loader: false,
        showMessage: true,
        messageStatus: "error",
        message: payload.userMessage,
        status: payload.status,
        errorMessage: payload.userMessage,
      };
    }

    case SIGNOUT_AUTH_USER_SUCCESS: {
      // //console.log({
      //   payload,
      //   SIGNOUT_AUTH_USER_SUCCESS: "SIGNOUT_AUTH_USER_SUCCESS",
      // });
      return {
        undefined,
      };
    }

    case ALL_CLEAR_SUCCESS: {
      return {
        ...state,
        user: {},
        accessToken: null,
        routeLevel: 0,
      };
    }
    default:
      return state;
  }
}

export default AuthReducer;
