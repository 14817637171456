import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { selectedDataClientContacts } from "../../appRedux/actions/clientContacts";

const EllipsisText = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // maxWidth: CHIP_MAX_WIDTH - CHIP_ICON_WIDTH,
      }}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "center",
    borderRadius: "0.75rem",
    minHeight: "100px",
    marginBottom: "10px",
    flexWrap: "wrap",
    // padding: theme.spacing(0, 0, 0),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  chip: {
    // maxWidth: CHIP_MAX_WIDTH,
    margin: "4px 6px 4px 6px",
    fontSize: "12px",
  },
}));

export default function ChipsValue({}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { clientContacts, selectedData } = useSelector((state) => ({
    clientContacts: state.clientContacts.clientContacts,
    selectedData: state.clientContacts.selectedData,
  }));

  const { values, touched, errors } = selectedData;

  // const [inputValue, setInputValue] = useState("");
  const [newOptions, setNewOptions] = useState("");
  // const [empList, setEmpList] = useState(employList);
  const [options, setOptions] = useState([]);
  const handleDelete = (chipToDelete) => () => {
    //console.log({ chipToDelete });
    const result = values.contactIds.filter(
      (elem) => elem.email !== chipToDelete.email
    );
    setOptions((o) => o.concat({ ...chipToDelete }));
    //console.log({ chipToDelete, result });
    dispatch(
      selectedDataClientContacts({
        values: { ...values, contactIds: result },
        touched: { ...touched, contactIds: true },
        errors: {
          ...errors,
          contactIds:
            result.length > 0 ? "" : "At least one email address is required.",
        },
      })
    );
  };
  useEffect(() => {
    //console.log({ clientContacts }, "useEffect");
    if (newOptions !== "") {
      const result = clientContacts.filter((elem) => elem.email !== newOptions);
      const result2 = clientContacts.filter(
        (elem) => elem.email === newOptions
      );
      setOptions(result);
      dispatch(
        selectedDataClientContacts({
          values: {
            ...values,
            contactIds: values.contactIds.concat(result2),
          },
          errors: {
            ...errors,
            contactIds: values.contactIds.concat(result2).length
              ? ""
              : "At least one email address is required.",
          },
          touched: { ...touched, contactIds: true },
        })
      );
    } else {
      setNewOptions("");
      setOptions(clientContacts);
    }
  }, [clientContacts]);
  return (
    <div className="px-4 justify-start items-start">
      {values.contactIds
        .sort((a, b) => a.email - b.email)
        .map((data) => {
          return (
            <Tooltip title={data.email} key={data._id}>
              <Chip
                size="small"
                key={data._id + "_clip"}
                // icon={icon}
                label={<EllipsisText>{data.email}</EllipsisText>}
                onDelete={handleDelete(data)}
                className={classes.chip}
              />
            </Tooltip>
          );
        })}
    </div>
  );
}
