import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
// import TagFacesIcon from "@material-ui/icons/TagFaces";
import { TextField } from "@material-ui/core";
import { Label } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { selectedDataClientContacts } from "../../appRedux/actions/clientContacts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "center",
    borderRadius: "0.75rem",
    // minHeight: "100px",
    marginBottom: "10px",
    flexWrap: "wrap",
    // padding: theme.spacing(0, 0, 0),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  chip: {
    // maxWidth: CHIP_MAX_WIDTH,
    margin: "4px 6px 4px 6px",
    fontSize: "12px",
  },
}));

export default function SubjectValue() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedData } = useSelector((state) => ({
    selectedData: state.clientContacts.selectedData,
  }));
  const { values, touched, errors } = selectedData;
  return (
    <>
      <Paper className={`${classes.root} `}>
        <Label color={"black"}>Subject</Label>
        <div className="mb-1 mt-0 mx-4 bg-bb w-full" id="removed">
          <TextField
            id="standard-multiline-static"
            multiline
            type="text"
            variant="standard"
            fullWidth
            size="small"
            value={values.subject}
            minRows={2}
            maxRows={2}
            max={40}
            onChange={(e) => {
              dispatch(
                selectedDataClientContacts({
                  values: { ...values, subject: e.target.value },
                  touched: { ...touched, subject: true },
                  errors: {
                    ...errors,
                    subject:
                      e.target.value !== "" ? "" : "Subject is required. ",
                  },
                })
              );
            }}
            onBlur={(e) => {
              dispatch(
                selectedDataClientContacts({
                  errors: {
                    ...errors,
                    subject:
                      values.subject !== "" ? "" : "Subject is required. ",
                  },
                  touched: { ...touched, subject: true },
                })
              );
            }}
          />
        </div>
        {/* <p className="text-red-500 px-4">
          {touched.subject && errors.subject && errors.subject !== ""
            ? errors.subject
            : ""}
        </p> */}
      </Paper>
    </>
  );
}
