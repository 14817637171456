import { useState } from "react";
import { Link, useHref, useLocation, useNavigate } from "react-router-dom";
import Navbar from "@material-tailwind/react/Navbar";
import NavbarContainer from "@material-tailwind/react/NavbarContainer";
import NavbarWrapper from "@material-tailwind/react/NavbarWrapper";
import NavbarBrand from "@material-tailwind/react/NavbarBrand";
import NavbarToggler from "@material-tailwind/react/NavbarToggler";
import NavbarCollapse from "@material-tailwind/react/NavbarCollapse";
import Nav from "@material-tailwind/react/Nav";
import Button from "@material-tailwind/react/Button";
import { CardImage, Image } from "@material-tailwind/react";
import Logo from "../../assets/img/logo-1600x400.png";

export default function DefaultNavbar() {
  const [openNavbar, setOpenNavbar] = useState(false);
  const navigate = useNavigate();
  // const href = useHref();
  const location = useLocation();
  //console.log("href", { location });
  const { pathname } = location;
  return (
    <Navbar color="transparent" navbar>
      <NavbarContainer>
        <NavbarWrapper>
          <Link to="/home" className="capitalize">
            <NavbarBrand className="capitalize">
              <CardImage
                src={Logo}
                className=" pt-10"
                style={{ boxShadow: "none", width: "200px" }}
              />
            </NavbarBrand>
          </Link>
        </NavbarWrapper>

        <NavbarCollapse open={openNavbar} className="py-1">
          <Nav>
            <div className="flex flex-col z-50 lg:flex-row lg:items-center py-4">
              {pathname === "/auth/login" && (
                <span className="ml-2 font-medium text-sm capitalize  text-white">
                  Don’t have an account? &nbsp;
                  <Link
                    ripple="light"
                    to="/auth/register"
                    className="active text-light-blue-500"
                  >
                    Join Now
                  </Link>
                </span>
              )}

              {/* Don’t have an account? Get started */}
              {pathname === "/auth/register" && (
                <span className="ml-2 font-medium text-sm capitalize  text-white">
                  Already have an account? &nbsp;
                  <Link
                    ripple="light"
                    to="/auth/login"
                    className="active text-light-blue-500"
                  >
                    Login
                  </Link>
                </span>
              )}
            </div>
          </Nav>
        </NavbarCollapse>
      </NavbarContainer>
    </Navbar>
  );
}
