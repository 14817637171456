import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePagination from "../common/Pagination";
import Button from "@material-tailwind/react/Button";
import AWS from "aws-sdk";
import async from "async";
import SendIcon from "@mui/icons-material/Send";
import H5 from "@material-tailwind/react/Heading5";
import { bytesToSize } from "../../utils/fileConstant";

import {
  addClientContact,
  selectedDataClientContacts,
} from "../../appRedux/actions/clientContacts";

import {
  DataList,
  PaginationView,
  EmailChips,
  SubjectValue,
  MessageValue,
  UploadView,
  DropZoneAreaInner,
  AddFile,
  AttachFolder,
  SelectView,
} from ".";
import { getAllClientContacts } from "../../appRedux/actions/clientContacts";
import {
  deleteTransfers,
  sendTransfer,
  sendTransferEmail,
  setTransferBucketStatus,
} from "../../appRedux/actions/transfer";
import { useNavigate } from "react-router-dom";
import { setFileStatus, setProgress } from "../../appRedux/actions/common";

import {
  CONTABO_STORAGE_BUCKET_URL,
  ACCESS_KEY_HERE,
  SECRET_ACCESS_KEY_HERE,
  BUCKET_NAME_HERE,
  HTTP_OPTIONS,
} from "../../utils/config";
import { addDialogValue } from "../../appRedux/actions/dialog";

const S3_BUCKET = BUCKET_NAME_HERE;
AWS.config.update({
  endpoint: CONTABO_STORAGE_BUCKET_URL,
  accessKeyId: ACCESS_KEY_HERE,
  secretAccessKey: SECRET_ACCESS_KEY_HERE,
  s3BucketEndpoint: true,
  signatureVersion: "v4",
  maxRetries: 2,
  httpOptions: HTTP_OPTIONS,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  // region: REGION,
});

const checkValidation = (newValue, selectedData, transferData, dispatch) => {
  const { values, touched, errors } = selectedData;

  //console.log({ selectedData, values, transferData });
  // Object.keys(values).every((k) => values[k].length > 0);
  delete values.transferData;
  const { contactIds } = values;
  console.log({ status: contactIds.length > 0 || newValue !== null });
  if (
    (contactIds.length > 0 || newValue !== null) &&
    // Object.keys(values).every(
    //   (k) => values[k].length > 0 || k === "subject" || k === "message"
    // )
    transferData.length > 0
  ) {
    return true;
  } else {
    dispatch(
      selectedDataClientContacts({
        touched: {
          ...touched,
          subject: true,
          message: true,
          contactIds: true,
          transferData: true,
        },
        errors: {
          ...errors,
          subject: values.subject.trim() !== "" ? "" : "Subject is required. ",
          message: values.message.trim() !== "" ? "" : "Message is required. ",
          contactIds:
            values.contactIds.length > 0 || newValue !== null
              ? ""
              : "At least one email address is required.",
          transferData:
            transferData.length > 0 ? "" : "Select at least one file.",
        },
      })
    );

    return false;
  }
};
const View = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subject, setSubject] = useState({ value: "", error: false });
  const [message, setMessage] = useState({ value: "", error: false });
  const [emailList, setEmailList] = useState([]);

  const {
    user,
    fileListInfo,
    numOfFile,
    numOfFolder,
    totalSize,
    selectedData,
    setBucketData,
    transferSuccessInfo,
    transferCancel,
    progress,
    newValue,
  } = useSelector((state) => ({
    user: state.user.user,
    fileListInfo: state.dropZone.fileListInfo,
    numOfFile: state.dropZone.numOfFile,
    numOfFolder: state.dropZone.numOfFolder,
    totalSize: state.dropZone.totalSize,
    selectedData: state.clientContacts.selectedData,
    newValue: state.clientContacts.newValue,
    setBucketData: state.transfer.setBucketData,
    transferSuccessInfo: state.transfer.transferSuccessInfo,
    transferCancel: state.transfer.transferCancel,
    progress: state.common.progress,
    numberOfFile: state.common.numberOfFile,
  }));

  let [page, setPage] = useState(1);
  const PER_PAGE = 10; //Object.keys(fileListInfo).length; //
  // const count = Math.ceil(Object.keys(fileListInfo).length / PER_PAGE);
  // const _DATA = usePagination(Object.keys(fileListInfo), PER_PAGE);

  useEffect(() => {
    dispatch(getAllClientContacts());
  }, []);
  useEffect(() => {
    if (setBucketData && transferSuccessInfo && transferSuccessInfo._id) {
      const { transferData, totalSize, _id } = transferSuccessInfo;
      var numberOfFile = 1;
      var totalLoaded = 0;
      var request;
      // request.abort();
      dispatch(setFileStatus({ numberOfFile, totalSize }));
      async.mapSeries(
        fileListInfo,
        function (info, next) {
          if (!transferCancel) {
            var loadedFile = 0;
            const params = {
              ACL: "public-read",
              Body: info.files,
              Bucket: S3_BUCKET + "/" + user._id,
              Key: info.alteredName,
            };
            request = myBucket
              .putObject(params)
              .on("httpUploadProgress", (evt) => {
                loadedFile = evt.loaded;
                dispatch(setProgress({ loaded: totalLoaded + evt.loaded }));
              })
              .send((err, data) => {
                totalLoaded = totalLoaded + loadedFile;
                dispatch(
                  setFileStatus({ numberOfFile, totalSize, totalLoaded })
                );
                numberOfFile = numberOfFile + 1;
                if (err) {
                  //console.log(err);
                  next(err);
                } else {
                  next(err, {
                    link: info.alteredName,
                  });
                }
              });
            // .abort(() => {
            //   request.abort();
            // });
          } else {
            // request.abort();
            next(null);
          }
        },
        function (err, results) {
          if (!transferCancel) {
            dispatch(setProgress({ progress: progress >= 99 ? 100 : 99 }));
            dispatch(sendTransferEmail({ transferId: _id }));
            const timeout = setTimeout(() => {
              dispatch(setTransferBucketStatus({ status: false, info: {} }));
            }, 1000);
            return () => {
              clearTimeout(timeout);
            };
          } else {
            dispatch(deleteTransfers({ transferId: _id }));
          }
        }
      );
    }
  }, [setBucketData]);

  const count = Math.ceil(fileListInfo.length / PER_PAGE);
  const _DATA = usePagination(fileListInfo, PER_PAGE);
  const handleChange = (e, p) => {
    //console.log({ e, p });
    setPage(p);
    _DATA.jump(p);
  };
  //console.log({ fileListInfo, count }, "-------------+++++++++++++=");
  const submitNow = async () => {
    const { values, touched, errors } = selectedData;
    const { contactIds } = values;
    console.log({ values });
    if (await checkValidation(newValue, selectedData, fileListInfo, dispatch)) {
      dispatch(
        sendTransfer({
          ...selectedData.values,
          email: newValue || undefined,
          contactIds: contactIds.length > 0 ? contactIds.map((a) => a._id) : [],
          transferData: fileListInfo, // listData,
          numOfFile,
          numOfFolder,
          totalSize,
          totalFiles: numOfFile + numOfFolder,
        })
      );
    }
  };
  const { values, touched, errors } = selectedData;
  console.log({ newValue });
  return (
    <div className={`  flex flex-wrap `}>
      <div className="w-full md:w-8/12 px-4  py-1">
        <div className="w-full py-1">
          <div className={`  flex flex-wrap `}>
            {count > 0 && <AddFile fileListInfo={fileListInfo} _DATA={_DATA} />}
          </div>
          <div className={`  flex flex-wrap `}>
            {count > 0 && (
              <div className="flex  w-full items-start justify-start  ">
                <div className="flex ">
                  <H5 color="gray">Our Transfer Files</H5>{" "}
                </div>
                <div className=" flex py-2  ">
                  {" "}
                  &nbsp; &nbsp;
                  <p className={`  text-gray-600   `}>
                    {`${
                      numOfFile > 0
                        ? `${numOfFile} ${numOfFile > 1 ? "Files" : "File"} ${
                            numOfFolder > 0
                              ? `and ${`${numOfFolder} ${
                                  numOfFolder > 1 ? "Folders" : "Folder"
                                }`}`
                              : ""
                          } added `
                        : `  ${
                            numOfFolder > 0
                              ? `${`${numOfFolder} ${
                                  numOfFolder > 1 ? "Folders" : "Folder"
                                }`}`
                              : "No file "
                          } added `
                    } `}
                  </p>
                  <br />
                  <p className={`  text-gray-600   `}>
                    &nbsp;
                    {` (${bytesToSize(totalSize)})`}
                  </p>
                </div>
              </div>
            )}
          </div>
          {count > 0 ? (
            <DataList fileListInfo={fileListInfo} _DATA={_DATA} />
          ) : (
            <div>
              <DropZoneAreaInner />
              <AttachFolder
                linkView={true}
                // handleClose={() => //console.log("close")}
              />

              <p className="text-red-500 px-4 text-center justify-center">
                {touched.transferData &&
                errors.transferData &&
                errors.transferData !== ""
                  ? errors.transferData
                  : ""}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="w-full md:w-4/12 px-2   ">
        <div className="w-full">
          <div className="flex  w-full items-end justify-end bg-bb py-2">
            <Button
              color="lightBlue"
              buttonType="submit"
              size="sm"
              ripple="dark"
              // disabled={count > 0 ? false : true}
              className="w-full"
              onClick={() => {
                submitNow();
                // if (newValue === "" || newValue === null) {
                //   submitNow();
                // } else {
                //   dispatch(addClientContact({ email: newValue }));
                //   // dispatch(
                //   //   addDialogValue({
                //   //     email: newValue,
                //   //     name: "",
                //   //     companyName: "",
                //   //   })
                //   // );
                //   dispatch(selectedDataClientContacts(null));
                // }
              }}
            >
              <span className="   font-medium text-xl capitalize">
                Transfer Now
              </span>{" "}
              <SendIcon fontSize="small" />
            </Button>
          </div>
          <div className="w-full py-2">
            <SelectView />
          </div>
          <div className="w-full py-2">
            <SubjectValue subject={subject} setSubject={setSubject} />
          </div>
          <div className="w-full py-2">
            <MessageValue message={message} setMessage={setMessage} />
          </div>
        </div>
      </div>

      <div className={`  flex flex-wrap   w-full  `}>
        <div className="w-full md:w-8/12 px-4  py-6">
          {count >= 9 && (
            <PaginationView
              count={count}
              page={page}
              handleChange={handleChange}
            />
          )}
        </div>
        <div className="w-full md:w-4/12 px-2 mx-auto items-center flex justify-center mt-24 lg:mt-0"></div>
      </div>
    </div>
  );
};

export default View;
