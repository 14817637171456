import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../../components/common/Header";
import { TablePagination } from "@material-ui/core";
// components
import { ListView, ListToolbar } from "../../../components/Contacts";
import { getClientContact } from "../../../appRedux/actions/clientContacts";
import { onChangeSearchName } from "../../../appRedux/actions/transfer";

export default function Contacts() {
  const dispatch = useDispatch();

  const { isUpdate, queryInfo, page, limit, totalRecords } = useSelector(
    (state) => ({
      isUpdate: state.clientContacts.isUpdate,
      queryInfo: state.clientContacts.queryInfo,
      page: state.clientContacts.queryInfo.page,
      limit: state.clientContacts.queryInfo.limit,
      totalRecords: state.clientContacts.totalRecords,
    })
  );

  useEffect(() => {
    if (isUpdate) {
      // dispatch(initURL("#"));
      dispatch(getClientContact({ ...queryInfo }));
    } else {
      // dispatch(initURL("#"));
      dispatch(onChangeSearchName(""));
      // onChangeSearchName
      dispatch(getClientContact({ ...queryInfo, page: 0, limit: 10 }));
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(getClientContact({ ...queryInfo, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch();
    getClientContact({ ...queryInfo, page: 0, limit: event.target.value });
  };
  return (
    <main>
      <Header pageName="Contacts List" />
      <section className="relative py-16 mt-30 bg-gray-100 ">
        <div className="container max-w-7xl px-4 mx-auto">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
            <div className="px-6">
              {/* <Card > */}
              <ListToolbar />
              <div className="px-6">
                <ListView />

                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={totalRecords}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
              {/* </Card> */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
