import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Collapse,
  Box,
  Button as ButtonMat,
} from "@material-ui/core";
import Label from "./Label";
import SearchNotFound from "../SearchNotFound";
import ResultNotFound from "../ResultNotFound";
// import { ListHead, MoreMenu, CustomizedDialogs, ListViewCourse } from ".";
import { ListHead, MoreMenu, InnerList } from ".";
import { Icon, Button } from "@material-tailwind/react";
import { bytesToSize } from "../../utils/fileConstant";
import { fDate, fExpiredAt, mDate, mTime } from "../../utils/formatTime";
import { deleteTransfers } from "../../appRedux/actions/transfer";
//

// ----------------------------------------------------------------------

function Row(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { row, columnHandling, queryInfo } = props;
  const { _id, subject, contactsInfo, totalSize, expireAt, receivedTransfer } =
    row;
  const [open, setOpen] = React.useState(false);
  const [contactInfo, setContactInfo] = useState([]);
  const [transferReceived, setTransferReceived] = useState([]);

  const downloaded =
    receivedTransfer && receivedTransfer.length > 0
      ? receivedTransfer.reduce((downloaded, obj) => {
          return downloaded + obj.downloaded;
        }, 0)
      : 0;
  const diffTime = fExpiredAt(expireAt);
  return (
    <React.Fragment>
      <TableRow key={_id + "_TableRow_0"}>
        {columnHandling.map((col, index) => {
          const { id, key, childKey, align } = col;
          //console.log({ contactsInfo });
          if (key === "sendTo") {
            return (
              <TableCell
                key={key}
                id="buttonTable"
                sx={{ padding: "6px 16px" }}
              >
                <Button
                  color="transparent"
                  rounded={true}
                  aria-label="expand row"
                  size="regular"
                  onClick={() => {
                    setOpen(!open);
                    setContactInfo(contactsInfo);
                    setTransferReceived(receivedTransfer);
                  }}
                  className="text-black  "
                >
                  {open ? (
                    <Icon name={"expand_less"} size="2xl" color={"black"} />
                  ) : (
                    <Icon name={"expand_more"} size="2xl" color={"black"} />
                  )}
                </Button>
              </TableCell>
            );
          }
          if (key === "subject") {
            return (
              <TableCell key={key} align={align} sx={{ padding: "6px 16px" }}>
                {subject || "..."}
              </TableCell>
            );
          }
          if (key === "downloaded") {
            return (
              <TableCell key={key} align={align} sx={{ padding: "6px 16px" }}>
                {downloaded}
              </TableCell>
            );
          }
          if (key === "totalSize") {
            return (
              <TableCell key={key} align={align} sx={{ padding: "6px 16px" }}>
                {bytesToSize(totalSize)}
              </TableCell>
            );
          }
          if (key === "createdAt") {
            return (
              <TableCell align={align} sx={{ padding: "6px 16px" }}>
                <p className="text-sm ">{mTime(row[key])}</p>
                <span className="text-[12px] font-bold">{mDate(row[key])}</span>
                {/* <p className={`text-sm `}>{diffYMDHMS(row[key])}</p> */}
              </TableCell>
            );
          }
          if (key === "expireAt") {
            return (
              <TableCell align={align} sx={{ padding: "6px 16px" }}>
                <Label color={diffTime.status}>{diffTime.msg}</Label>
              </TableCell>
            );
          }
          if (key === "_id") {
            return (
              <TableCell
                key={key}
                align={"center"}
                id="buttonTable2"
                sx={{ padding: "6px 16px" }}
              >
                <Button
                  color={`${
                    diffTime.status === "error" ? "gray" : "lightBlue"
                  }`}
                  className="py-1 capitalize   mx-2 "
                  buttonType="filled"
                  onClick={() => {
                    // if (diffTime.status !== "error") {
                    navigate("/history/" + _id);
                    // }
                  }}
                  // disabled={`${diffTime.status === "error" ? true : false}`}
                >
                  View
                </Button>
                <Button
                  // color="red"
                  color={`${diffTime.status === "error" ? "gray" : "red"}`}
                  className="py-1 my-1 capitalize mx-2"
                  buttonType="filled"
                  onClick={() => {
                    // if (diffTime.status !== "error") {
                    dispatch(deleteTransfers({ transferId: _id, queryInfo }));
                    // }
                  }}
                  // disabled={`${diffTime.status === "error" ? true : false}`}
                >
                  Delete
                </Button>
              </TableCell>
            );
          }
          if (key === "active") {
            return (
              <TableCell key={key} align="center" sx={{ padding: "6px 16px" }}>
                <Label
                  variant="ghost"
                  color={(row[key] === false && "error") || "success"}
                >
                  {row[key] === false ? "Banned" : "Active"}
                </Label>
              </TableCell>
            );
          }
          if (key === "createdAt") {
            // return <TableCell align={align}>{row[key]}</TableCell>;
            return (
              <TableCell key={key} align={align} sx={{ padding: "6px 16px" }}>
                {fDate(row[key])}
              </TableCell>
            );
          }
          return key === "action" ? (
            <TableCell key={key} align={align} sx={{ padding: "6px 16px" }}>
              <MoreMenu learnerId={_id} index={index} />
            </TableCell>
          ) : (
            <TableCell key={key} align={align} sx={{ padding: "6px 16px" }}>
              {row[key]}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow key={_id + "_TableRow_1"}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            key={_id + "_Collapse"}
          >
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Sent To
              </Typography>
              {/* <InnerList contactInfo={contactInfo} /> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "none" }}>Email</TableCell>
                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      Name
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      Company Name
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      Downloaded
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      Have Seen
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody key={_id + "_TableBody"}>
                  {contactInfo.map((contact) => {
                    var received =
                      transferReceived
                        .filter((obj) => obj.contactId == contact._id)
                        .pop() || {};
                    return (
                      <TableRow key={contact._id} sx={{ borderBottom: "none" }}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ borderBottom: "none" }}
                        >
                          {contact.email}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: "none" }}>
                          {contact.name || "..."}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: "none" }}>
                          {contact.companyName || "..."}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: "none" }}>
                          {received.downloaded || 0}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: "none" }}>
                          <Label
                            variant="ghost"
                            color={received.readStatus ? "success" : "error"}
                          >
                            {received.readStatus ? "Yes" : "No"}
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default function ListView() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [courseInfo, setCourseInfo] = useState([]);
  // const [columnHandling, setColumnHandling] = useState([]);

  const {
    searchName,
    transferList,
    columnHandling,
    searchStatus,
    queryInfo,
    limit,
  } = useSelector((state) => ({
    transferList: state.transfer.transferList,
    searchStatus: state.transfer.searchStatus,
    // searchName: state.transfer.searchName,
    searchName: state.transfer.queryInfo.searchText,
    page: state.transfer.queryInfo.page,
    limit: state.transfer.queryInfo.limit,
    queryInfo: state.transfer.queryInfo,
    columnHandling: state.transfer.columnHandling.filter(
      (item) => item.visibility || !item.isFilter
    ),
  }));

  useEffect(() => {
    // setColumnHandling(columnHandling2);
  }, []);
  const emptyRows = 0;

  // const emptyRows = Math.max(0, limit - transferList.length) || 0;
  // const emptyRows = page > 0 ? Math.max(0, limit - learnerList.length) : 0;
  // //console.log({ emptyRows, emptyRows2: limit, length: learnerList.length });
  const props = {
    title: "All Course",
    courseInfo,
    visible,
    footer: null,
    onCancel: () => setVisible(false),
  };
  return (
    <>
      <TableContainer sx={{ minWidth: 280 }}>
        <Table>
          <ListHead
            headLabel={columnHandling}
            checkbox={false}
            isSortLabel={false}
          />
          <TableBody>
            {transferList &&
              transferList.length > 0 &&
              transferList.map((row) => (
                <Row
                  key={row._id + "_row_9"}
                  row={row}
                  columnHandling={columnHandling}
                  queryInfo={queryInfo}
                />
              ))}
          </TableBody>
          <TableBody>
            {emptyRows > 0 && transferList.length !== 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          {transferList.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                  {searchName && searchName !== "" ? (
                    <SearchNotFound searchQuery={searchName} />
                  ) : (
                    <ResultNotFound resultsQuery={` History`} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {/* <CustomizedDialogs>
        <ListViewCourse alt="example" {...props} />
      </CustomizedDialogs> */}
    </>
  );
}
