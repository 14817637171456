import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
import { View } from "../../components/TransferItNow";

export default function TransferItNow() {
  const navigate = useNavigate();
  const { initURL } = useSelector((state) => ({
    initURL: state.common.initURL,
  }));
  // initURL(pin):"/history"
  useEffect(() => {
    if (initURL === "/history") {
      // navigate(initURL);
      const timeout = setTimeout(() => {
        navigate(initURL);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [initURL]);
  return (
    <main>
      <Header pageName="Transfer Now" />
      <section className="relative mt-30 py-16  bg-gray-100 ">
        <div className="container max-w-7xl px-4 mx-auto">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
            <div className="p-6">
              <View />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
