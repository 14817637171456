export const RESIDENCE_CREATE = 'RESIDENCE_CREATE';
export const RESIDENCE_CREATE_SUCCESS = 'RESIDENCE_CREATE_SUCCESS';
export const RESIDENCE_CREATE_ERROR = 'RESIDENCE_CREATE_ERROR';
export const RESIDENCE_LIST = 'RESIDENCE_LIST';
export const RESIDENCE_LIST_SUCCESS = 'RESIDENCE_LIST_SUCCESS';
export const RESIDENCE_LIST_ERROR = 'RESIDENCE_LIST_ERROR';
export const RESIDENCE_INFO = 'RESIDENCE_INFO';
export const RESIDENCE_INFO_SUCCESS = 'RESIDENCE_INFO_SUCCESS';
export const RESIDENCE_INFO_ERROR = 'RESIDENCE_INFO_ERROR';
export const RESIDENCE_UPDATE_SUCCESS = 'RESIDENCE_UPDATE_SUCCESS';
export const RESIDENCE_UPDATE_ERROR = 'RESIDENCE_UPDATE_ERROR';
export const RESIDENCE_DELETE_SUCCESS = 'RESIDENCE_DELETE_SUCCESS';
export const RESIDENCE_FILTER_HANDLING = 'RESIDENCE_FILTER_HANDLING';
export const RESIDENCE_SEARCH_NAME = 'RESIDENCE_SEARCH_NAME';
export const RESIDENCE_CATEGORY_SUCCESS = 'RESIDENCE_CATEGORY_SUCCESS';
