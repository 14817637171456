import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// npm i formik  yup
import { tokenRemove, userSignIn } from "../../appRedux/actions/auth";
import { showLoader } from "../../appRedux/actions/common";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H5 from "@material-tailwind/react/Heading5";
import InputIcon from "@material-tailwind/react/InputIcon";
import Checkbox from "@material-tailwind/react/Checkbox";
import Button from "@material-tailwind/react/Button";

const INITIAL_STATE = {
  email: "",
  password: "",
  // departmentId: [],
};
export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { tempToken, authLevel, loader } = useSelector((state) => ({
    loader: state.common.loader,
    authLevel: state.auth.authLevel,
    tempToken: state.auth.tempToken,
  }));
  useEffect(() => {
    if (tempToken) dispatch(tokenRemove());
  }, []);
  const FormSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_STATE,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      //console.log({ data }, "update");
      dispatch(showLoader());
      dispatch(userSignIn({ ...data }));
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  //console.log({ errors, touched, values });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <Card>
      <CardHeader color="lightBlue" className="h-auto" size="sm">
        <H5 color="white" style={{ marginBottom: 0 }}>
          Login
        </H5>
      </CardHeader>

      <CardBody>
        <FormikProvider value={formik}>
          <Form
            id="contactForm"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            className="signin-form"
          >
            <div className="mb-4 mt-8 px-4 bg-bb">
              <InputIcon
                type="email"
                outline={true}
                color="lightBlue"
                placeholder="Email Address"
                iconName="email"
                {...getFieldProps("email")}
              />
              <span className="text-red-600 px-2 text-xs">
                {touched.email && errors.email}
              </span>
            </div>
            <div className="mb-8 px-4">
              <InputIcon
                type="password"
                outline={true}
                color="lightBlue"
                placeholder="Password"
                iconName="lock"
                {...getFieldProps("password")}
              />
              <span className="text-red-600 px-2 text-xs">
                {touched.password && errors.password}
              </span>
            </div>
            <div className="flex mb-4 px-4">
              <div className=" w-full  text-left">
                <Checkbox color="lightBlue" text="Remember Me" id="remember" />
              </div>
              <div className="  w-full  text-right">
                <Link
                  ripple="light"
                  to="/auth/forgot-password"
                  className="active text-light-blue-500 text-right"
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className="flex justify-center bg-bb py-2.5">
              <Button
                color="lightBlue"
                buttonType="submit"
                size="lg"
                ripple="dark"
              >
                Login
              </Button>
            </div>
            <div className="flex justify-center bg-bb py-2.5 do-not-auth">
              <span className="ml-2  text-xs  justify-center text-sm capitalize ">
                Don’t Have An Account? &nbsp;
                <Link
                  ripple="light"
                  to="/auth/register"
                  className="active text-light-blue-500 font-medium"
                >
                  Join Now
                </Link>
              </span>
            </div>
          </Form>
        </FormikProvider>
      </CardBody>
    </Card>
  );
}
