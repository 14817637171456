import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// npm i formik  yup
import {
  tokenRemove,
  userCodeSend,
  userSignIn,
} from "../../appRedux/actions/auth";
import { showLoader } from "../../appRedux/actions/common";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H5 from "@material-tailwind/react/Heading5";
import InputIcon from "@material-tailwind/react/InputIcon";
import Checkbox from "@material-tailwind/react/Checkbox";
import Button from "@material-tailwind/react/Button";

const INITIAL_STATE = {
  email: "",
};
export default function Forgot() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { tempToken, authLevel, loader } = useSelector((state) => ({
    loader: state.common.loader,
    authLevel: state.auth.authLevel,
    tempToken: state.auth.tempToken,
  }));
  useEffect(() => {
    if (tempToken) dispatch(tokenRemove());
  }, []);
  const FormSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_STATE,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      //console.log({ data }, "update");
      dispatch(showLoader());
      dispatch(userCodeSend({ ...data, type: "Forgot" }));
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  //console.log({ errors, touched, values });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <Card>
      <CardHeader color="lightBlue" className="h-auto" size="sm">
        <H5 color="white" style={{ marginBottom: 0 }}>
          Forgot your password?
        </H5>
      </CardHeader>

      <div className="mb-2 px-4 bg-bb text-center">
        <span className="  text-xs ">
          Please enter the email address associated with your account.
        </span>
      </div>
      <CardBody className="pb-0">
        {/* and We will
        email you a link to reset your password. */}
        <FormikProvider value={formik}>
          <Form
            id="contactForm"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            className="signin-form"
          >
            <div className="mb-12 mt-4 px-4 bg-bb">
              <InputIcon
                type="email"
                outline={true}
                color="lightBlue"
                placeholder="Email Address"
                iconName="email"
                {...getFieldProps("email")}
              />
              <span className="text-red-600 text-xs">
                {touched.email && errors.email}
              </span>
            </div>
            <div className="flex justify-center bg-bb py-2.5">
              <Button
                color="lightBlue"
                buttonType="submit"
                size="lg"
                ripple="dark"
              >
                Send Request
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </CardBody>
      <CardFooter className="p-0">
        <div className="flex justify-center bg-bb">
          <Button
            color="lightBlue"
            buttonType="link"
            size="lg"
            ripple="dark"
            onClick={() => navigate("/auth/login")}
          >
            BacK
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
