import { CLIENT_UPDATE_SUCCESS, CLIENT_ANALYTICS } from "../actionTypes/client";
import {
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
} from "../actionTypes/common";
import { userSignOut } from "./auth";
// import AllDataService from '../services/all.service';

import axiosHttp from "../services/axiosCommon";
// getClientList;

export const getClientAnalytics = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/client/analytics", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: CLIENT_ANALYTICS,
        payload: data.data,
      });
      dispatch({
        type: ON_HIDE_LOADER,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const updateClient = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  //console.log({ infoData }, "------------");
  try {
    const result = await axiosHttp.put("/client", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      localStorage.setItem("user", JSON.stringify(data.data));
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: CLIENT_UPDATE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
//

export const updateClientProfile = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  //console.log({ infoData }, "------------");
  try {
    const result = await axiosHttp.post("/media/profile/client", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      localStorage.setItem("user", JSON.stringify(data.data));
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: CLIENT_UPDATE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
