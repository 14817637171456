export const getFileType = (file) => {
  if (file.type.match("image.*")) return { type: "image", icon: "image" };

  if (file.type.match("video.*")) return { type: "video", icon: "movie" };

  if (file.type.match("audio.*")) return { type: "audio", icon: "audio" };
  if (file.type.match("application/pdf"))
    return { type: "pdf", icon: "fa fa-file-pdf", family: "font-awesome" };
  if (file.type.match("application/msword"))
    return { type: "word", icon: "fa fa-word", family: "font-awesome" };
  if (file.type.match("application/x-zip-compressed"))
    return { type: "zip", icon: "folder_zip" };
  // application/x-zip-compressed
  if (
    file.type.match(
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    )
  )
    return { type: "docx", icon: "fa fa-file", family: "font-awesome" };
  if (
    file.type.match(
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
  )
    return { type: "xlsx", icon: "fa fa-file-excel", family: "font-awesome" };

  switch (file.name.split(".").pop()) {
    case "jpg":
    case "gif":
    case "png":
    case "bmp":
      return { type: "image", icon: "image" };
    case "zip":
      return { type: "zip", icon: "folder_zip" };
    case "pdf":
      return { type: "pdf", icon: "fa fa-file-pdf", family: "font-awesome" };
    case "txt":
      return { type: "txt", icon: "fa fa-file", family: "font-awesome" };
    case "doc":
    case "docx":
      return { type: "doc", icon: "fa fa-file", family: "font-awesome" };

    case "xls":
    case "xlsx":
    case "xlr":
      return {
        type: "xlsx",
        icon: "fa fa-file-excel",
        family: "font-awesome",
      };

    case "mdf":
      return {
        type: "mdf",
        icon: "fa fa-file",
        family: "font-awesome",
      };
    case "ppt":
    case "pptx":
      return {
        type: "ppt",
        icon: "fa fa-file-powerpoint",
        family: "font-awesome",
      };
    case "msg":
    case "log":
    case "bak":
    case "files":
    case "file":
      return {
        type: "txt",
        icon: "fa fa-file",
        family: "font-awesome",
      };
    case "xml":
      return {
        type: "xml",
        icon: "fa fa-file",
        family: "font-awesome",
      };
    case "html":
    case "htm":
    case "htl":
      return {
        type: "html",
        icon: "fa fa-file",
        family: "font-awesome",
      };
    case "mp3":
    case "m4a":
    case "m3u":
    case "mid":
    case "wma":
    case "wav":
      return { type: "audio", icon: "audio" };

    case "mp4":
    case "mpg":
    case "m4v":
    case "vob":
    case "mnv":
    case "rm":
    case "mov":
    case "flv":
    case "wmv":
      return { type: "video", icon: "movie" };

    default:
      return "other";
  }

  // etc...
  //     application/pdf means .pdf
  // application/msword means .doc
  // application/vnd.openxmlformats-officedocument.wordprocessingml.document means .docx

  // return "other";
};

export const bytesToSize = (bytes) => {
  // var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  // if (bytes == 0) return "0 Byte";
  // var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  // return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  if (bytes === 0) return "0 Bytes";
  var decimals = 2;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
export const getTotalSize = (list) => {
  var totalSize = 0;
  Object.keys(list).map((info) => {
    let isFolder = info.split(".").length > 1 ? false : true;
    let fileInfo = isFolder ? {} : list[info][0];
    const size = isFolder
      ? list[info].reduce((accumulator, object) => {
          return accumulator + object.size;
        }, 0)
      : fileInfo.size;
    totalSize = totalSize + size;
  });
  return totalSize;
};
