import { AUTH_INIT_STATE } from "./auth";
import { CLIENT_UPDATE_SUCCESS } from "../actionTypes/client";
import {
  SIGNOUT_AUTH_USER_SUCCESS,
  USER_CONTACT_US,
  USER_CONTACT_US_SUCCESS,
} from "../actionTypes/auth";
export const INIT_STATE = {
  user: AUTH_INIT_STATE.user,
  contactStatus: false,
};

function AuthReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNOUT_AUTH_USER_SUCCESS: {
      return {
        user: {},
      };
    }
    case CLIENT_UPDATE_SUCCESS: {
      //console.log({ payload }, "CLIENT_UPDATE_SUCCESS");
      return {
        ...state,
        user: { ...payload.data },
      };
    }

    case USER_CONTACT_US_SUCCESS: {
      return {
        ...state,
        contactStatus: false,
      };
    }
    case USER_CONTACT_US: {
      return {
        ...state,
        contactStatus: true,
      };
    }
    default:
      return state;
  }
}

export default AuthReducer;
