import React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
// import CircularProgress from "@mui/material/CircularProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-tailwind/react/Button";
import useWindowDimensions from "../../components/common/WindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import { bytesToSize } from "../../utils/fileConstant";
import { setTransferCencel } from "../../appRedux/actions/transfer";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress size="200px" variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          sx={{ fontSize: "40px", color: "#ffffff" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic({ open }) {
  const dispatch = useDispatch();
  const { progress, loadedSize, totalSize, loadedFileSize, numberOfFile } =
    useSelector((state) => ({
      progress: state.common.progress,
      loadedSize: state.common.loadedSize,
      totalSize: state.common.totalSize,
      loadedFileSize: state.common.loadedFileSize,
      numberOfFile: state.common.numberOfFile,
    }));
  const { height, width } = useWindowDimensions();

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <div>
        <CircularProgressWithLabel value={progress > 100 ? 100 : progress} />
        {/* <br /> */}
        <p className="text-center m-3">
          {bytesToSize(loadedFileSize)} of {bytesToSize(totalSize)}
        </p>
        {/* <Button
          className="m-auto"
          color="lightBlue"
          buttonType="submit"
          size="sm"
          ripple="dark"
          onClick={() => {
            dispatch(setTransferCencel());
          }}
        >
          <span className="font-medium text-xl capitalize">Cencel</span>
        </Button> */}
      </div>
    </Backdrop>
  );
}
