import axios from 'axios';
import { apiURL } from '../../utils/config';

const axiosAPI = axios.create({
  baseURL: apiURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken'); // sessionStorage.getItem("jwt");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete axiosAPI.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

export default axiosAPI;
