import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";

// layouts
import LoginLayout from "./layouts/authLayout";
import PrivateLayout from "./layouts/privateLayout";
import PublicLayout from "./layouts/publicLayout";
// pages
import Login from "./pages/AuthPages/Login";
import Register from "./pages/AuthPages/Register";
import Forgot from "./pages/AuthPages/Forgot";
import NewPassword from "./pages/AuthPages/NewPassword";
import VerifyCode from "./pages/AuthPages/VerifyCode";
import Landing from "./pages/Landing";
import ContactUs from "./pages/ContactUs";
import Profile from "./pages/Profile";
import AboutUs from "./pages/AboutUs";
// import TransferIt from "pages/TransferIt";
import FAQPage from "./pages/InformedPage/FAQ";
import HelpPage from "./pages/InformedPage/Help";

// Font Awesome Style Sheet
import "@fortawesome/fontawesome-free/css/all.min.css";

// Tailwind CSS Style Sheet
import "./assets/styles/tailwind.css";
import "./assets/styles/custom.css";
import TermsConditions from "./pages/InformedPage/TermsConditions";
import PrivacyPolicy from "./pages/InformedPage/PrivacyPolicy";
// import History from "pages/privatePage/History";
import { ContactList } from "./pages/privatePage/Contacts";
import TransferItNow from "./pages/privatePage/TransferItNow";
import { HistoryList, HistoryDetails } from "./pages/privatePage/History/index";
import { DetailsInfo } from "./pages/privatePage/Details";

// ----------------------------------------------------------------------
// const ROUTE_MENU = [
//   {
//     path: "/home",
//     element: <LogoOnlyLayout />,
//     accessLevel: [1, 2, 3, 4, 5, 6],
//     children: [
//       // { path: "/", element: <Navigate to="/dashboard/app" replace /> },
//       { path: "/", element: <Landing /> },
//       { path: "", element: <Landing /> },
//       // { path: "products", element: <Products /> },
//       // { path: "blog", element: <Blog /> },
//     ],
//   },
//   {
//     path: "/associate",
//     element: <DashboardLayout />,
//     accessLevel: [1],
//     children: [
//       { path: "list", element: <AssociateList /> },
//       { path: "new", element: <AssociateCreate /> },
//       { path: ":associateId/update", element: <AssociateUpdate /> },
//       { path: ":associateId/info", element: <AssociateUpdate /> },
//     ],
//   },
//   {
//     path: "/tenant",
//     element: <DashboardLayout />,
//     accessLevel: [2, 3, 4],
//     children: [
//       { path: "list", element: <TenantList /> },
//       { path: "new", element: <TenantCreate /> },
//       // { path: 'assign', element: <Assigned /> },
//       { path: ":tenantId/update", element: <TenantUpdate /> },
//       { path: ":tenantId/info", element: <TenantUpdate /> },
//     ],
//   },
//   {
//     path: "/tenant",
//     element: <DashboardLayout />,
//     accessLevel: [5],
//     children: [{ path: "", element: <TenantList /> }],
//   },
//   {
//     path: "/landlord",
//     element: <DashboardLayout />,
//     accessLevel: [2, 3, 4, 5, 6],
//     children: [
//       { path: "list", element: <LandlordList /> },
//       { path: "new", element: <LandlordCreate /> },
//       { path: "/:landlordId/update", element: <LandlordUpdate /> },
//       { path: "/:landlordId/info", element: <LandlordUpdate /> },
//     ],
//   },
//   {
//     path: "/residence",
//     element: <DashboardLayout />,
//     accessLevel: [2, 3, 4, 5, 6],
//     children: [
//       { path: "add", element: <ResidenceCreate /> },
//       { path: "/:listType/list", element: <ResidenceList /> },
//       { path: "categories", element: <ResidenceCategories /> },
//       { path: "/:residenceId/update", element: <ResidenceUpdate /> },
//       { path: "/:residenceId/info", element: <ResidenceUpdate /> },
//     ],
//   },
//   {
//     path: "/assign-residence",
//     element: <DashboardLayout />,
//     accessLevel: [2, 3, 4, 5, 6],
//     children: [
//       { path: "new", element: <Assigned /> },
//       { path: "/:listType/list", element: <AssignedList /> },
//       { path: "/:residenceId/:type", element: <Assigned /> },
//       { path: "/:residenceId/info", element: <Assigned /> },
//       { path: "/:residenceId/occupied-history", element: <AssignedList /> },
//       { path: "/occupied-history", element: <AssignedList /> },
//     ],
//   },
//   {
//     path: "/admin",
//     element: <DashboardLayout />,
//     accessLevel: [2, 3, 4, 5, 6],
//     children: [
//       { path: "list", element: <AdminList /> },
//       { path: "new", element: <AdminCreate /> },
//     ],
//   },
// ];

export default function Router() {
  const [level, setLevel] = useState();
  const { accessToken, routeLevel } = useSelector((state) => ({
    routeLevel: state.auth.routeLevel,
    accessToken: state.auth.accessToken,
  }));
  //

  useEffect(() => {
    // if (showMessage) dispatch(hideMessage());
    //console.log({ accessToken }, "------------------");
  }, [accessToken]);

  const COMMON_ROUTE = [
    {
      path: "/profile",
      element: (
        <Navigate to={accessToken !== null ? "/profile" : "/home"} replace />
      ),
    },
    {
      path: "/profile",
      element: <PrivateLayout />,
      children: [{ path: "/profile", element: <Profile /> }],
    },
    {
      path: "/history",
      element: <PrivateLayout />,
      children: [
        { path: "/history", element: <HistoryList /> },
        { path: "/history/:transferId", element: <HistoryDetails /> },
        { path: "/history/:transferId/:status", element: <HistoryDetails /> },
      ],
    },
    {
      path: "/downloads",
      element: accessToken !== null ? <PrivateLayout /> : <PublicLayout />,
      children: [
        { path: "/downloads/:transferId/:contactId", element: <DetailsInfo /> },
      ],
    },
    {
      path: "/contacts",
      element: <PrivateLayout />,
      children: [{ path: "/contacts", element: <ContactList /> }],
    },
    {
      path: "/home",
      element: accessToken !== null ? <PrivateLayout /> : <PublicLayout />,
      children: [{ path: "/home", element: <Landing /> }],
    },
    {
      path: "/contact-us",
      element: accessToken !== null ? <PrivateLayout /> : <PublicLayout />,
      children: [{ path: "/contact-us", element: <ContactUs /> }],
    },
    {
      path: "/about",
      element: accessToken !== null ? <PrivateLayout /> : <PublicLayout />,
      children: [{ path: "/about", element: <AboutUs /> }],
    },
    {
      path: "/transferit",
      element: <PrivateLayout />,
      children: [{ path: "/transferit", element: <TransferItNow /> }],
    },
    // {
    //   path: "/transferit",
    //   element: <TransferLayout />,
    //   children: [{ path: "/transferit", element: <TransferIt /> }],
    // },
    {
      path: "/help",
      element: accessToken !== null ? <PrivateLayout /> : <PublicLayout />,
      children: [{ path: "/help", element: <HelpPage /> }],
    },
    {
      path: "/frequently-asked-questions",
      element: accessToken !== null ? <PrivateLayout /> : <PublicLayout />,
      children: [{ path: "/frequently-asked-questions", element: <FAQPage /> }],
    },
    {
      path: "/terms-conditions",
      element: accessToken !== null ? <PrivateLayout /> : <PublicLayout />,
      children: [{ path: "/terms-conditions", element: <TermsConditions /> }],
    },
    {
      path: "/privacy-policy",
      element: accessToken !== null ? <PrivateLayout /> : <PublicLayout />,
      children: [{ path: "/privacy-policy", element: <PrivacyPolicy /> }],
    },
    {
      path: "/auth",
      element: <LoginLayout />,
      children: [
        { path: "/auth/login", element: <Login /> },
        { path: "/auth/register", element: <Register /> }, //forgot-password
        { path: "/auth/forgot-password", element: <Forgot /> }, //
        { path: "/auth/new-password/:tempId", element: <NewPassword /> }, //
        { path: "/auth/verify-code/:tempId", element: <VerifyCode /> }, //

        // { path: "404", element: <NotFound /> },
        // {
        //   path: "/auth",
        //   element: (
        //     <Navigate
        //       to={accessToken !== null ? "/home" : "/auth/login"}
        //       replace
        //     />
        //   ),
        // },
        // { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/",
      element: accessToken !== null ? <PrivateLayout /> : <PublicLayout />,
      children: [
        {
          path: "/",
          element: (
            <Navigate
              to={accessToken === null ? "/home" : "/transferit"}
              replace
            />
          ),
        },
      ],
    },
    // {
    //   path: "/",
    //   element: <TransferLayout />,
    //   children: [{ path: "", element: <TransferIt /> }],
    // },
    // { path: "*", element: <Navigate to="/404" replace /> },
  ];

  return useRoutes(COMMON_ROUTE);
  // return useRoutes(FILTER_ROUTE.concat(COMMON_ROUTE));
}
