import {
  CLIENT_CONTACT_CREATE_SUCCESS,
  CLIENT_CONTACT_LIST,
  CLIENT_CONTACT_LIST_SUCCESS,
  CLIENT_CONTACT_INFO_SUCCESS,
  CLIENT_CONTACT_UPDATE_SUCCESS,
  CLIENT_CONTACT_DELETE_SUCCESS,
  CLIENT_CONTACT_FILTER_HANDLING,
  CLIENT_CONTACT_SEARCH_NAME,
  CLIENT_CONTACT_SELECTED,
  CLIENT_CONTACT_SELECTED_TUCHED,
  CLIENT_CONTACT_EDIT_TABLE,
  CLIENT_CONTACT_SELECTED_CONTACT,
  CLIENT_CONTACT_SELECTED_SET_NEW_VALUE,
} from "../actionTypes/clientContacts";
import {
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
} from "../actionTypes/common";
import { userSignOut } from "./auth";
// import AllDataService from '../services/all.service';

import axiosHttp from "../services/axiosCommon";
import { DIALOG_HIDE } from "../actionTypes/dialog";
// getClientContactList;

export const selectedDataSetNewValue = (dataInfo) => async (dispatch) => {
  //console.log({ dataInfo });
  dispatch({
    type: CLIENT_CONTACT_SELECTED_SET_NEW_VALUE,
    payload: dataInfo,
  });
};
export const selectedDataClientContacts = (dataInfo) => async (dispatch) => {
  //console.log({ dataInfo });
  dispatch({
    type: CLIENT_CONTACT_SELECTED,
    payload: dataInfo,
  });
};

export const getAllClientContacts = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/client/contact/list", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      dispatch({
        type: CLIENT_CONTACT_LIST,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getClientContact = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  // searchText
  const { page, searchText } = queryInfo;
  try {
    const result = await axiosHttp.get("/client/contact", {
      params: queryInfo,
    });
    const { data } = result;
    if (data.error && data.status !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/client/contact/list",
      // });
      if ((!searchText || searchText === "") && page === 0) {
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: data,
        });
      } else {
        dispatch({ type: ON_HIDE_LOADER });
      }
      dispatch({
        type: CLIENT_CONTACT_LIST_SUCCESS,
        payload: { ...data, queryInfo },
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getClientContactInfo = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/client/contact", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      dispatch({
        type: CLIENT_CONTACT_INFO_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const addClientContact = (infoData) => async (dispatch) => {
  //console.log({ infoData }, "addClientContact-------------+++++++++++===");
  const { queryInfo } = infoData;
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.post("/client/contact", {
      ...infoData,
      queryInfo: undefined,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/client/contact/list",
      // });
      if (!queryInfo) {
        dispatch({ type: DIALOG_HIDE });
        dispatch({ type: CLIENT_CONTACT_SELECTED_CONTACT, payload: data });
      }
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      if (queryInfo) {
        dispatch(getClientContact(queryInfo));
      }
      dispatch({
        type: CLIENT_CONTACT_CREATE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const updateClientContact = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  //console.log({ infoData }, "------------");
  try {
    const result = await axiosHttp.put("/client/contact", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/client/contact/list",
      // });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: CLIENT_CONTACT_UPDATE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const deleteClientContact = (infoData) => async (dispatch) => {
  const { queryInfo } = infoData;
  //console.log({ queryInfo });
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.delete("/client/contact", {
      params: {
        ...infoData,
        queryInfo: undefined,
      },
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/client/contact/list",
      // });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });

      if (queryInfo) {
        dispatch(getClientContact(queryInfo));
      }
      dispatch({
        type: CLIENT_CONTACT_DELETE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.userMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: CLIENT_CONTACT_SEARCH_NAME,
    payload: info,
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: CLIENT_CONTACT_FILTER_HANDLING,
    payload: info,
  });
};

export const onEditTable = (info) => async (dispatch) => {
  dispatch({
    type: CLIENT_CONTACT_EDIT_TABLE,
    payload: info,
  });
};
