import React, { useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-tailwind/react/Button";
import FileCopy from "@mui/icons-material/FileCopy";
import $ from "jquery";

import JSZip from "jszip";
import throttle from "lodash.throttle";

import async from "async";
import { setFileData } from "../../appRedux/actions/dropZone";

import { useDispatch, useSelector } from "react-redux";

export default function AttachFolder({ handleClose, openFolder, linkView }) {
  const dispatch = useDispatch();
  //console.log({ openFolder });
  const importFile = (e) => {
    //console.log("hiiiii");
    $("#importFile").val("");
    $("#importFile").trigger("click");
  };
  const changeFile = (e) => {
    e.preventDefault();
    var acceptedFiles = e.target.files;
    //console.log({ acceptedFiles });
    var result = Array.from(acceptedFiles).reduce(function (r, a) {
      let url = a.webkitRelativePath.split("/");
      let fileName = url.length > 1 ? url[1] : a.name;
      r[fileName] = r[fileName] || [];
      r[fileName].push(a);
      return r;
    }, Object.create(null));
    //console.log({ acceptedFiles, result });
    // dispatch(setFileData(result));

    //

    async.mapSeries(
      Object.keys(result),
      function (info, next) {
        let isFolder = info.split(".").length > 1 ? false : true;
        let fileInfo = isFolder ? {} : result[info][0];
        let size = isFolder
          ? result[info].reduce((accumulator, object) => {
              return accumulator + object.size;
            }, 0)
          : fileInfo.size;
        if (isFolder) {
          onZip(result[info], function (err, data) {
            if (err) {
              next(err);
            } else {
              next(null, {
                name: info,
                type: "Folder",
                size: size,
                // bucket: Math.random(),
                alteredName:
                  new Date().getTime() +
                  "_" +
                  Math.random().toString().split(".").pop() +
                  ".zip",
                files: data,
                numberOfFile: result[info].length,
              });
            }
          });
        } else {
          next(null, {
            name: info,
            type: fileInfo.type,
            size: size,
            // bucket: Math.random(),
            alteredName:
              new Date().getTime() +
              "_" +
              Math.random().toString().split(".").pop() +
              "." +
              info.split(".").pop(),
            files: result[info][0],
            numberOfFile: result[info].length,
          });
        }
      },
      function (err, results) {
        //console.log({ err, results });
        // callback(err, listSuccess);

        dispatch(setFileData(results));
        // navigate("/transferit");
        handleClose();
      }
    );
    handleClose();
  };

  const importFolder = (e) => {
    //console.log("hiiiii");

    $("#importFolder").val("");
    $("#importFolder").trigger("click");
  };
  const changeFolder = (e, openFolder) => {
    //console.log({ openFolder }, "open");
    e.preventDefault();
    var files = e.target.files;
    var webkitRelativePath = files[0].webkitRelativePath.split("/")[0];
    // dispatch(setFileData({ [webkitRelativePath]: Array.from(files) }));
    var result = { [webkitRelativePath]: Array.from(files) };
    //console.log({ result }, "FOlder-----");
    async.mapSeries(
      Object.keys(result),
      function (info, next) {
        let isFolder = info.split(".").length > 1 ? false : true;
        let fileInfo = isFolder ? {} : result[info][0];
        let size = isFolder
          ? result[info].reduce((accumulator, object) => {
              return accumulator + object.size;
            }, 0)
          : fileInfo.size;
        if (isFolder) {
          onZip(result[info], function (err, data) {
            if (err) {
              next(err);
            } else {
              next(null, {
                name: info,
                type: "Folder",
                size: size,
                // bucket: Math.random(),
                alteredName:
                  new Date().getTime() +
                  "_" +
                  Math.random().toString().split(".").pop() +
                  ".zip",
                files: data,
                numberOfFile: result[info].length,
              });
            }
          });
        } else {
          next(null, {
            name: info,
            type: fileInfo.type,
            size: size,
            // bucket: Math.random(),
            alteredName:
              new Date().getTime() +
              "_" +
              Math.random().toString().split(".").pop() +
              "." +
              info.split(".").pop(),
            files: result[info][0],
            numberOfFile: result[info].length,
          });
        }
      },
      function (err, results) {
        //console.log({ err, results });
        // callback(err, listSuccess);

        if (!err) {
          dispatch(setFileData(results));
        }
        // navigate("/transferit");

        if (openFolder) {
          handleClose();
        }
      }
    );
    handleClose();
  };
  const [progress, setProgress] = useState(-1);

  const onZipUpdate = (metadata) => {
    setProgress(metadata.percent);
    //console.log("progression: " + metadata.percent.toFixed(2) + " %");
    if (metadata.currentFile) {
      //console.log("current file = " + metadata.currentFile);
    }
  };
  const throttledZipUpdate = throttle(onZipUpdate, 50);

  const onZip = function onZip(fileList, callback) {
    const zip = new JSZip();
    const files = Array.from(fileList);
    // setFiles(inputRef.current.files);
    files.forEach((file) => {
      zip.file(file.webkitRelativePath, file);
    });
    zip
      .generateAsync({ type: "blob" }, throttledZipUpdate)
      .then(function (content) {
        callback(null, content);
        // saveAs(content, "files.zip");

        // const formData = new FormData();
        // formData.append("folderzip", content);
        // //console.log("ready to send to server", content);
      })
      .catch((e) => callback(e));
  };
  return (
    <>
      {linkView ? (
        <Button
          color="transparent"
          size="sm"
          buttonType="link"
          onClick={(e) => importFolder()}
          className="w-full px-0"
        >
          <div className="w-full font-medium flex justify-center items-center   p-0 text-black capitalize">
            <div
              className={`pl-1  py-0.5 text-[14px] items-center justify-center  `}
            >
              <p className="hover:text-red-700">
                {" "}
                <span> Or </span>{" "}
                <span className="hover:text-orange-700">
                  Click to select folder
                </span>{" "}
              </p>
            </div>
          </div>
        </Button>
      ) : (
        <>
          {" "}
          {openFolder ? (
            <MenuItem onClick={(e) => importFolder()} sx={{ color: "#03a9f4" }}>
              <FileCopy fontSize="small" />
              &nbsp; &nbsp; {`Folder`}
            </MenuItem>
          ) : (
            <MenuItem onClick={(e) => importFile()} sx={{ color: "#03a9f4" }}>
              <FileCopy fontSize="small" />
              &nbsp; &nbsp; {`File`}
            </MenuItem>
          )}
        </>
      )}

      <input
        type="file"
        id="importFile"
        // name="files[]"
        onChange={changeFile}
        style={{ display: "none" }}
        multiple={true}
      />
      <input
        type="file"
        id="importFolder"
        name="files[]"
        onChange={(e) => changeFolder(e, openFolder)}
        style={{ display: "none" }}
        multiple={true}
        webkitdirectory="true"
        mozdirectory="true"
        msdirectory="true"
        odirectory="true"
        directory="true"
      />
    </>
  );
}
