export const INIT_URL = "INIT_URL";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_MESSAGE = "ON_HIDE_MESSAGE";
export const ON_SHOW_MESSAGE = "ON_SHOW_MESSAGE";
export const SUCCESS_MESSAGE = "SUCCESS_MESSAGE";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const ON_COMPOSE_STATUS = "ON_COMPOSE_STATUS";

export const SET_PROGRESS = "SET_PROGRESS";
export const SET_FILE_STATUS = "SET_FILE_STATUS";
export const SET_DOWNLOAD = "SET_DOWNLOAD";
