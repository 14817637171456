import { SIGNOUT_AUTH_USER_SUCCESS } from "../actionTypes/auth";
import { CLIENT_UPDATE_SUCCESS, CLIENT_ANALYTICS } from "../actionTypes/client";

const INIT_STATE = {
  clients: [],
  clientList: [],
  isSuccess: false,
  clientInfo: {},
  queryInfo: {
    page: 0,
    limit: 10,
  },
  totalRecords: 0,
  page: 0,
  columnHandling: [],
  searchName: "",
  searchStatus: false,
  clientType: "Active",
  isUpdate: false,
  isClient: true,
  analytics: { totalContact: 0, totalReceived: 0, totalSend: 0 },
};

function clientReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNOUT_AUTH_USER_SUCCESS: {
      return {
        clients: [],
        clientList: [],
        isSuccess: false,
        clientInfo: {},
        queryInfo: {
          page: 0,
          limit: 10,
        },
        totalRecords: 0,
        page: 0,
        columnHandling: [],
        searchName: "",
        searchStatus: false,
        clientType: "Active",
        isUpdate: false,
        isClient: true,
        analytics: { totalContact: 0, totalReceived: 0, totalSend: 0 },
      };
    }
    case CLIENT_ANALYTICS: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          totalContact: payload.totalContact
            ? payload.totalContact
            : state.analytics.totalContact,
          totalReceived: payload.totalReceived
            ? payload.totalReceived
            : state.analytics.totalReceived,
          totalSend: payload.totalSend
            ? payload.totalSend
            : state.analytics.totalSend,
        },
      };
    }
    // case CLIENT_UPDATE_SUCCESS: {
    //   //console.log({ payload });
    //   return {
    //     ...state,
    //     clientInfo: payload.data,
    //   };
    // }

    default:
      return state;
  }
}

export default clientReducer;
