import { combineReducers } from "redux";
import auth from "./auth";
import common from "./common";
import associate from "./associate";
import dialog from "./dialog";
import dropZone from "./dropZone";
import assigned from "./assigned";
import client from "./client";
import clientContacts from "./clientContacts";
import residence from "./residence";
import transfer from "./transfer";
import user from "./user";

export default combineReducers({
  assigned,
  associate,
  auth,
  common,
  client,
  clientContacts,
  residence,
  dialog,
  dropZone,
  transfer,
  user,
});
