import Title from "../landing/Title";
import ContactCard from "../landing/ContactCard";

export default function ContactSection() {
  return (
    // <section className="pb-20 relative block  ">
    <section className="pb-20 relative block ">
      <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
        <Title heading="Advantages">
          An alternative for file-sharing, free of cost.
        </Title>

        <div className="flex flex-wrap -mt-12 justify-center">
          <ContactCard icon="stars" title="Free and Robust">
            TransferItNow allows users to effectively share files upto 4GB, for
            free.
          </ContactCard>
          <ContactCard icon="insert_chart" title="De-clutter your inbox ">
            Keep better track of incoming files while saving on email storage
            space.
          </ContactCard>
          <ContactCard icon="launch" title="Effective and Efficient">
            Securely transfer files in any format(s) in minimal time.
          </ContactCard>
        </div>

        {/* <Form /> */}
      </div>
    </section>
  );
}
