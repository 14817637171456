export const CLIENT_CONTACT_CREATE = "CLIENT_CONTACT_CREATE";
export const CLIENT_CONTACT_CREATE_SUCCESS = "CLIENT_CONTACT_CREATE_SUCCESS";
export const CLIENT_CONTACT_CREATE_ERROR = "CLIENT_CONTACT_CREATE_ERROR";
export const CLIENT_CONTACT_LIST = "CLIENT_CONTACT_LIST";
export const CLIENT_CONTACT_LIST_SUCCESS = "CLIENT_CONTACT_LIST_SUCCESS";
export const CLIENT_CONTACT_LIST_ERROR = "CLIENT_CONTACT_LIST_ERROR";
export const CLIENT_CONTACT_INFO = "CLIENT_CONTACT_INFO";
export const CLIENT_CONTACT_INFO_SUCCESS = "CLIENT_CONTACT_INFO_SUCCESS";
export const CLIENT_CONTACT_INFO_ERROR = "CLIENT_CONTACT_INFO_ERROR";
export const CLIENT_CONTACT_UPDATE_SUCCESS = "CLIENT_CONTACT_UPDATE_SUCCESS";
export const CLIENT_CONTACT_UPDATE_ERROR = "CLIENT_CONTACT_UPDATE_ERROR";
export const CLIENT_CONTACT_DELETE_SUCCESS = "CLIENT_CONTACT_DELETE_SUCCESS";
export const CLIENT_CONTACT_FILTER_HANDLING = "CLIENT_CONTACT_FILTER_HANDLING";
export const CLIENT_CONTACT_SEARCH_NAME = "CLIENT_CONTACT_SEARCH_NAME";
export const CLIENT_CONTACT_SELECTED = "CLIENT_CONTACT_SELECTED";
export const CLIENT_CONTACT_SELECTED_CONTACT =
  "CLIENT_CONTACT_SELECTED_CONTACT";
export const CLIENT_CONTACT_SELECTED_TUCHED = "CLIENT_CONTACT_SELECTED_TUCHED";
export const CLIENT_CONTACT_EDIT_TABLE = "CLIENT_CONTACT_EDIT_TABLE";
export const CLIENT_CONTACT_SELECTED_SET_NEW_VALUE =
  "CLIENT_CONTACT_SELECTED_SET_NEW_VALUE";
