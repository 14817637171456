export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_ERROR = "SIGNIN_USER_ERROR";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const ALL_CLEAR_SUCCESS = "ALL_CLEAR_SUCCESS";
export const FIND_USER_SUCCESS = "FIND_USER_SUCCESS";
export const ON_PING = "ON_PING";
export const ON_PING_SUCCESS = "ON_PING_SUCCESS";

export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "SIGNIN_GITHUB_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_MICROSOFT_USER = "SIGNIN_MICROSOFT_USER";
export const SIGNIN_MICROSOFT_USER_SUCCESS = "SIGNIN_MICROSOFT_USER_SUCCESS";

export const SIGNOUT_AUTH_USER = "SIGNOUT_AUTH_USER";
export const SIGNOUT_AUTH_USER_SUCCESS = "SIGNOUT_AUTH_USER_SUCCESS";
export const AUTH_LEVEL = "AUTH_LEVEL";
export const USER_CONTACT_US = "USER_CONTACT_US";
export const USER_CONTACT_US_SUCCESS = "USER_CONTACT_US_SUCCESS";
