import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// npm i formik  yup

import { Input, Button } from "@material-tailwind/react";
import { updateClientContact } from "../../appRedux/actions/clientContacts";
import { addClientContact } from "../../appRedux/actions/clientContacts";
import { onEditTable } from "../../appRedux/actions/clientContacts";

const INITIAL_STATE = {
  email: "",
  companyName: "",
  name: "",
  // departmentId: [],
};
export default function Login({ queryInfo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const FormSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
    name: Yup.string(),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    // companyName: Yup.string().required("Company name is required."),
    companyName: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_STATE,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      //console.log({ data }, "update");
      // dispatch(showLoader());
      dispatch(addClientContact({ ...data, queryInfo }));
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  //console.log({ errors, touched, values, FormSchema });

  return (
    <>
      <div className="flex display-flex w-full mt-6">
        <FormikProvider value={formik}>
          <Form
            id="contactForm"
            autoComplete="off"
            noValidate
            className="signin-form w-full flex display-flex"
          >
            <div className="  w-full px-4 bg-bb">
              <Input
                type="email"
                outline={true}
                color="lightBlue"
                placeholder="Email Address"
                // iconName="email"
                {...getFieldProps("email")}
                autoComplete="new-password"
              />
              <span className="text-red-600 px-2 text-xs">
                {touched.email && errors.email}
              </span>
            </div>
            <div className=" w-full px-4 bg-bb">
              <Input
                type="text"
                outline={true}
                color="lightBlue"
                placeholder="Full Name"
                // iconName="account_circle"
                {...getFieldProps("name")}
                autoComplete="new-password"
              />
              <span className="text-red-600 px-2 text-xs">
                {touched.name && errors.name}
              </span>
            </div>{" "}
            <div className="  w-full px-4">
              <Input
                type="text"
                outline={true}
                color="lightBlue"
                placeholder="Company Name"
                // iconName="lock"
                {...getFieldProps("companyName")}
                autoComplete="new-password"
              />
              <span className="text-red-600 px-2 text-xs">
                {touched.companyName && errors.companyName}
              </span>
            </div>
          </Form>
        </FormikProvider>
      </div>
      <div className="flex  w-max gap-4">
        <Button
          color="teal"
          className="py-1 capitalize hover:bg-teal-500 hover:text-white mx-2"
          buttonType="filled"
          onClick={() => {
            // setOpenFilter(false);
            handleSubmit();
          }}
        >
          Add
        </Button>
        <Button
          color="orange"
          className="py-1 capitalize hover:bg-orange-500 hover:text-white mx-2"
          buttonType="filled"
          onClick={() => {
            // setOpenFilter(false);
            dispatch(onEditTable({ id: "", status: false, isTrue: false }));
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
