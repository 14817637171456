import React from "react";
import Router from "./routes";

import ScrollToTop from "./components/ScrollToTop";
import AlertSnackbar from "./components/AlertSnackbar";
// import { SnackbarProvider, useSnackbar } from 'notistack';
// Font Awesome Style Sheet
import "@fortawesome/fontawesome-free/css/all.min.css";

// Tailwind CSS Style Sheet
import "./assets/styles/tailwind.css";
import "./assets/styles/custom.css";
import SimpleBackdrop from "./components/SimpleBackdrop";
// require("dotenv").config({ NODE_ENV: "Staging" });
// var dotenv = require("dotenv");
// var dotenvExpand = require("dotenv-expand");

// var myEnv = dotenv.config();
// dotenvExpand.expand(myEnv);

function App() {
  // //console.log(process.env, { myEnv, dotenvExpand });
  // //console.log({ myEnv, dotenvExpand });
  //console.log({ env: process.env, process }, "---------");
  return (
    <>
      <ScrollToTop />
      <SimpleBackdrop />
      <AlertSnackbar />
      <React.Suspense fallback={"Loading..."}>
        <Router></Router>
      </React.Suspense>
      {/* </AlertSnackbar> */}
    </>
  );
}

export default App;
