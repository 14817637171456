import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
// import TagFacesIcon from "@material-ui/icons/TagFaces";
import Tooltip from "@material-ui/core/Tooltip";
import SelectMultiple from "../../common/mui/SelectMultiple";
import { Label } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import {
  addClientContact,
  selectedDataClientContacts,
} from "../../../appRedux/actions/clientContacts";

const EllipsisText = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // maxWidth: CHIP_MAX_WIDTH - CHIP_ICON_WIDTH,
      }}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "center",
    borderRadius: "0.75rem",
    minHeight: "100px",
    marginBottom: "10px",
    flexWrap: "wrap",
    // padding: theme.spacing(0, 0, 0),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  chip: {
    // maxWidth: CHIP_MAX_WIDTH,
    margin: "4px 6px 4px 6px",
    fontSize: "12px",
  },
}));

export default function ChipsValue({}) {
  const classes = useStyles();
  const { transferInfo, contactList } = useSelector((state) => ({
    transferInfo: state.transfer.transferInfo,
    contactList:
      state.transfer.transferInfo && state.transfer.transferInfo._id
        ? state.transfer.transferInfo.contactList
        : [],
  }));

  return (
    <>
      <Paper className={`${classes.root} `}>
        <div>
          <Label color="black">Send Email</Label>
          <div>
            <div className="px-4 justify-start items-start">
              {contactList
                .sort((a, b) => a.email - b.email)
                .map((data) => {
                  return (
                    <Tooltip title={data.email} key={data._id}>
                      <Chip
                        size="small"
                        key={data._id + "_clip"}
                        // icon={icon}
                        label={<EllipsisText>{data.email}</EllipsisText>}
                        // onDelete={handleDelete(data)}
                        className={classes.chip}
                      />
                    </Tooltip>
                  );
                })}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
}
